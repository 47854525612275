<template>
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild p-2">
      <div class="row" style="border-bottom: 5px solid #bcbfc6">
        <p class="mainHeading d-flex ms-4 p-1 mt-2 mb-2">Total Purchase</p>
      </div>
      <div>
        <div class="row headerOfTable d-flex p-1">
          <div class="col-md-12 d-flex align-content-center">
            <div class="col-md-6 d-flex">
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary ms-2 h-50 mt-2"
                style="margin-right: 50px"
                v-if="ActivatedMonth"
                @click="backTo()"
              >
                Back
              </button>

              <div class="col-md-5 d-flex flex-column mb-3" v-if="showExecative">
                <label for="input1" class="form-label text-muted" style="font-size: 12px">
                  Executive Name
                </label>
                <select
                  style="font-size: 12px; height: 29px"
                  class="form-select"
                  v-model="selectedEmployeeId"
                  @change="setSelectedEmployee"
                >
                  <option value="All" selected>All Team Member</option>
                  <option
                    v-for="employee in employees"
                    :key="employee.employeeId"
                    :value="employee.employeeId"
                  >
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }}
                  </option>
                </select>
              </div>
              <div class="col-md-5 ms-3 d-flex flex-column mb-3" v-if="!ActivatedMonth">
                <label for="input2" class="form-label text-muted" style="font-size: 12px">
                  Financial Year
                </label>
                <select
                  style="font-size: 12px; height: 29px"
                  class="form-select"
                  v-model="selectedFinancialYear"
                  @change="updateSelectedYear"
                >
                  <option
                    v-for="year in financialYears"
                    :key="year.fromYear"
                    :value="year"
                  >
                    FY Apr {{ year.fromYear }} - Mar {{ year.toYear }}
                  </option>
                </select>
              </div>
              <div class="col-md-5 ms-3 d-flex flex-column mb-3" v-if="ActivatedMonth">
                <label for="input2" class="form-label text-muted" style="font-size: 12px">
                  Report -{{ this.selectedYear }}
                </label>
                <select
                  style="font-size: 12px; height: 29px"
                  class="form-select"
                  v-model="selectedMonth"
                  @change="GoToMonthview(selectedMonth)"
                >
                  <option
                    v-for="(monthName, monthNumber) in monthNames"
                    :key="monthNumber"
                    :value="monthNumber"
                  >
                    {{ monthName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- Table Section -->
        <div class="table-wrapper m-1" v-if="!ActivatedMonth">
          <table>
            <caption class="d-none">
              Table
            </caption>
            <thead>
              <tr style="font-size: 16px">
                <th>PO Month</th>
                <th class="text-end">Purchase Order Amount</th>
                <!-- <th>Purchase Invoice Amount</th>
                <th>Debit Note Amount</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="purchase in purchases.purchaseOrders" :key="purchase.id">
                <td @click="GoToMonthview(purchase.month)">
                  {{ getMonthName(purchase.month) }}
                </td>
                <td class="text-end">
                  {{ $formatToIndianCurrency(purchase.purchaseOrder) }}
                </td>
                <!-- <td class="text-end">
                  {{ $formatToIndianCurrency(purchase.purchaseInvoice) }}
                </td>
                <td class="text-end">
                  {{ $formatToIndianCurrency(purchase.debitNote) }}
                </td> -->
              </tr>
            </tbody>
            <tfoot style="font-size: 16px">
              <tr
                v-for="purchase in purchases.totalPurchase"
                :key="purchase.id"
                style="font-weight: bolder; background-color: #bcbfc6"
              >
                <td>Total Value</td>
                <td class="text-end">
                  {{ $formatToIndianCurrency(purchase.purchaseOrder) }}
                </td>
                <!-- <td class="text-end">
                  {{ $formatToIndianCurrency(purchase.purchaseInvoice) }}
                </td>
                <td class="text-end">
                  {{ $formatToIndianCurrency(purchase.debitNote) }}
                </td> -->
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="table-wrapper m-1" v-if="ActivatedMonth">
          <table>
            <caption class="d-none">
              Table
            </caption>
            <thead>
              <tr style="font-size: 16px">
                <th>PO Date</th>
                <th>Project Name</th>
                <th>
                  <span>Purchase Order</span> <br />
                  <div class="d-flex justify-content-between mt-1">
                    <span class="text-start text-muted">Document No.</span>
                    <span class="text-end text-muted">Amount</span>
                  </div>
                </th>

                <th>
                  <span>Purchase Invoice</span> <br />
                  <div class="d-flex justify-content-between mt-1">
                    <span class="text-start text-muted">Document No.</span>
                    <span class="text-end text-muted">Amount</span>
                  </div>
                </th>

                <th>
                  <span>Debit Note</span> <br />
                  <div class="d-flex justify-content-between mt-1">
                    <span class="text-start text-muted">Document No.</span>
                    <span class="text-end text-muted">Amount</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="purchase in PurchaseAmountBymonth" :key="purchase.id">
                <td>{{ purchase.date }}</td>
                <td class="text-left">
                  {{ purchase["projects.projectName"] }}
                </td>
                <td>
                  <div class="d-flex justify-content-between">
                    <span class="text-start">{{ purchase.poNumber }}</span>
                    <span class="text-end">
                      {{ $formatToIndianCurrency(purchase.totalAmount) }}
                    </span>
                  </div>
                </td>
                <td class="text-start">
                  <div v-if="purchase.invoiceData.length > 0">
                    <div
                      v-for="invoiceData in purchase.invoiceData"
                      :key="invoiceData.id"
                      class="d-flex justify-content-between"
                    >
                      <span class="text-start">{{ invoiceData.invoiceNumber }}</span>
                      <span class="text-end">
                        {{
                          invoiceData.amount !== null
                            ? $formatToIndianCurrency(invoiceData.amount)
                            : "-"
                        }}
                      </span>

                      <!-- <br v-if="index < purchase.invoiceData.length - 1" /> -->
                    </div>
                  </div>
                  <!-- <span v-else>-</span> -->
                </td>

                <td>
                  <div v-if="purchase.debitNoteData.length > 0">
                    <div
                      v-for="debitNote in purchase.debitNoteData"
                      :key="debitNote.id"
                      class="d-flex justify-content-between"
                    >
                      <span class="text-start">{{ debitNote.debitNoteNo }}</span>
                      <span class="text-end">
                        {{
                          debitNote.amount !== null
                            ? $formatToIndianCurrency(debitNote.amount)
                            : "-"
                        }}
                      </span>

                      <!-- <br v-if="index < purchase.debitNoteData.length - 1" /> -->
                    </div>
                  </div>
                  <!-- <span v-else>-</span> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";

export default {
  name: "PurchaseReport",
  components: {
    Header,
    Nav,
  },
  data() {
    const currentYear = new Date().getFullYear();
    const currentFinancialYear = {
      fromYear: currentYear,
      toYear: currentYear + 1,
    };

    const financialYears = [];

    // Add the top 5 previous financial years
    for (let i = 5; i > 0; i--) {
      financialYears.push({
        fromYear: currentYear - i,
        toYear: currentYear - i + 1,
      });
    }

    // Add the current financial year
    financialYears.push(currentFinancialYear);

    // Add the next 5 financial years
    for (let i = 1; i <= 5; i++) {
      financialYears.push({
        fromYear: currentYear + i,
        toYear: currentYear + i + 1,
      });
    }
    return {
      PurchaseAmountBymonth: [],
      selectedMonth: null,
      ActivatedMonth: false,
      userRoles: this.$LocalUser.employeeRoles,
      showExecative: false,
      selectedEmployeeId: this.$LocalUser.user.employeeId,
      selectedYear: this.getFinancialYear(),
      selectedFinancialYear: currentFinancialYear,
      financialYears: financialYears,
      employees: [], // Placeholder for employee data
      purchases: [], // API response data
      monthNames: {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        10: "October",
        11: "November",
        12: "December",
      },
    };
  },
  mounted() {
    this.selectedYear = this.selectedFinancialYear.fromYear;
    console.log(this.selectedFinancialYear);
    this.fetchEmployees();
    this.fetchData();
    this.showExecative = this.userRoles.some(
      (role) => role.team === "Procurement" && role.role === "Manager"
    );
  },

  methods: {
    backTo() {
      this.ActivatedMonth = false;
      this.fetchData();
    },
    fetchDataByMonth(month) {
      console.log(month);
      console.log(this.selectedMonth);
      const url = `${this.$apiEndPoint}/purchaseOrder/totalPurchaseByMonth/${this.selectedYear}/${this.selectedMonth}/${this.selectedEmployeeId}`;
      axios
        .get(url)
        .then((response) => {
          this.PurchaseAmountBymonth = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    GoToMonthview(month) {
      this.ActivatedMonth = true;
      this.selectedMonth = month;
      if (this.selectedMonth) {
        this.fetchDataByMonth(this.selectedMonth);
      }
    },
    getMonthName(month) {
      return this.monthNames[month] || month;
    },
    getFinancialYear() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1; // getMonth() returns 0 for January, 1 for February, etc.

      if (month >= 4) {
        // Current financial year starts in this calendar year and ends in the next year
        return `${year}-${year + 1}`;
      } else {
        // Current financial year started in the previous calendar year and ends in this year
        return `${year - 1}-${year}`;
      }
    },
    updateSelectedYear() {
      this.selectedYear = this.selectedFinancialYear.fromYear;
      console.log(this.selectedYear);
      this.fetchData();
    },
    async fetchEmployees() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/employee/myTeam/Procurement`
        );
        this.employees = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    setSelectedEmployee() {
      this.fetchData();
      if (this.selectedMonth) {
        this.fetchDataByMonth(this.selectedMonth);
      }
    },
    fetchData() {
      const url = `${this.$apiEndPoint}/purchaseOrder/totalPurchase/${this.selectedYear}/${this.selectedEmployeeId}`;
      axios
        .get(url)
        .then((response) => {
          this.purchases = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
  },
};
</script>

<style scoped>
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
.content-container {
  display: flex;
}

.container-child {
  padding: 20px;
  flex-grow: 1;
}

.table-wrapper {
  max-height: 80%; /* Adjust the height based on your requirements */
  overflow-y: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead,
tfoot {
  background-color: #f9f9f9;
  position: sticky;
  z-index: 1;
}

thead {
  top: 0;
}

tfoot {
  bottom: 0;
}

th,
td {
  font-size: 12px;
  border: 1px solid #ddd;
  padding: 7px 8px;
  text-align: left;
  background-color: #ffffff;
}
th {
  background-color: #bcbfc6;
}
th span {
  font-size: 12px;
}
</style>
