<template>
  <desktop-nav id="desktopNav" />
</template>

<script>
import DesktopNav from "./DesktopNav.vue";
export default {
  components: { DesktopNav },
  name: "Nav",
  methods: {},
};
</script>
