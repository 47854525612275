<template>
  <div>
    <!-- Button to trigger the modal -->
    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#myTaskModal">
      Open Modal
    </button>

    <!-- Bootstrap modal -->
    <transition name="fade">
      <div v-if="myTaskModal" class="modal-overlay" @click="togglemyTaskModal"></div>
    </transition>
    <transition name="slide">
      <section class="MyTaskContentTask">
        <div class="modal fade" id="myTaskModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="btn btn-primary" @click="markTaskAsComplete">
                  <template v-if="selectedTask.status === 'Pending'">
                    Mark as complete
                  </template>
                  <template v-else>
                    Completed
                  </template>
                </button>
                <button type="button" class="btn-close" @click="togglemyTaskModal"></button>
              </div>
              <div class="modal-body">
                <div class="row mb-3">
                  <div class="col">
                    <label for="taskName" class="form-label">Task Name</label>
                    <input type="text" class="form-control" id="taskName" v-model="selectedTask.title">
                  </div>
                  <div class="col">
                    <label for="assignee" class="form-label">Assignee</label>
                    <input type="text" class="form-control" id="assignee" v-model="selectedTask.assignedTo">
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <label for="dueDate" class="form-label">Due Date</label>
                    <input type="text" class="form-control" id="dueDate" v-model="selectedTask.date">
                  </div>
                  <div class="col">
                    <label for="projectName" class="form-label">Project Name</label>
                    <input type="text" class="form-control" id="projectName" v-model="selectedTask.projectId">
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <label for="priority" class="form-label">Priority</label>
                    <select class="form-select" id="priority" v-model="selectedTask.priority">
                      <option value="0">Select</option>
                      <option value="1" style="background-color: #ffa07a">Low</option>
                      <option value="2" style="background-color: #ff6347">Medium</option>
                      <option value="3" style="background-color: #b84c4c">High</option>
                    </select>
                  </div>
                  <div class="col">
                    <label for="description" class="form-label">Description</label>
                    <textarea class="form-control" id="description" rows="5" v-model="selectedTask.description"></textarea>
                  </div>
                </div>
                <!-- Subtask content -->
                <div class="SubTask">
                  <div class="SubTaskContent">
                    <button @click="addSubTaskForm()">Add SubTask +</button>
                    <div class="AddSubTaskContent">
                      <div class="row">
                        <!-- Subtask details -->
                        <div class="col-md-6" v-for="(subTask, index) in subtasks" :key="index">
                          <div class="subTaskform">
                            <div class="subTaskHeader">
                              <input type="text" v-model="subTask.title" :name="'SubTaskName' + index"
                                :id="'SubTaskName' + index" placeholder="SubTaskName" class="SubTaskName"
                                @blur="saveSubTask(subTask)" />
                
                              <div class="subTaskHeaderItems">
                                <span id="numberOfSubTask">{{ subTask.subtaskCnt }}</span>
                                <img src="../../assets/icons/subtask.png" alt="" srcset="" style="width: 20px" />
                                <img src="../../assets/icons/taskDetail.png" alt="" srcset="" style="width: 20px" @click="
                                  openSubtaskModal(
                                    subTask,
                                    this.selectedTask.taskHeading
                                  )
                                  " />
                              </div>
                            </div>
                            <div class="headingSubTaskName">
                              <div class="head1">
                                <label :for="'Assignee' + index">Assignee</label>
                                <input type="text" v-model="subTask.assignedTo" :id="'assignedTo' + index"
                                  :name="'Assignee' + index" />
                              </div>
                              <div class="head2">
                                <label :for="'DueDate' + index">Due Date</label>
                                <input type="text" v-model="subTask.date" :id="'date' + index" :name="'date' + index"
                                  class="DueDate1" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Subtask comments -->
                  <div class="subTaskComments col-md-6">
                    <button>Comments</button>
                    <div class="taskComments">
                      <textarea v-model="newComment" placeholder="Write comments about task..." @blur="saveComment"
                        class="comments"></textarea>
                      <section class="taskComments-1">
                        <span v-for="comment in taskComments" :key="comment.id" class="taskCommentSpan">
                          <p class="commentIntials">{{ comment.users.initials }}</p>
                          <div class="commentDetails">
                            <div class="titleTime">
                              <p id="commentTitle">{{ comment.comment }}</p>
                              <p id="commentTime">{{ formatDate(comment.created_at) }}</p>
                            </div>
                            <div class="taskCommentTitle">
                              <p id="taskCommentTitle">{{ selectedTask.title }}</p>
                            </div>
                          </div>
                        </span>
                      </section>
                    </div>
                  </div>
                </div>
                
              </div>
              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-secondary" @click="togglemyTaskModal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </transition>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        myTaskModal: false,
        selectedTask: {
          title: '',
          assignedTo: '',
          date: '',
          projectId: '',
          priority: 0,
          description: '',
          status: 'Pending' // Assuming the initial status is 'Pending'
        }
      };
    },
    methods: {
      togglemyTaskModal() {
        this.myTaskModal = !this.myTaskModal;
      },
      markTaskAsComplete() {
        // Logic to mark task as complete
      }
    }
  };
  </script>
  
  <style scoped>
input:focus ,
textarea:focus ,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
  /* Add custom styles if needed */
  </style>
  