import axios from "axios";

const setAuthHeader = (accessToken) => {
    if (accessToken) {
        axios.defaults.headers=
        {
            Authorization:'Bearer '+accessToken
        };

    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

export default setAuthHeader;
