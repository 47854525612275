<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="addMaterialModal"
      tabindex="-1"
      aria-labelledby="addMaterialModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title SubheadingUnderLine" id="addMaterialModalLabel">
              Add New Material
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- Form fields for adding a new material -->
            <div class="mb-3">
              <label for="materialName" class="form-label text-center"
                >Material Name</label
              >
              <input
                type="text"
                class="form-control text-left"
                id="materialName"
                v-model="newMaterial.materialName"
              />
            </div>
            <div class="mb-3">
              <label for="materialDiscount" class="form-label text-center"
                >Discount (%)</label
              >
              <input
                type="number"
                class="form-control text-right"
                id="materialDiscount"
                v-model="newMaterial.discount"
              />
            </div>
            <div class="mb-3">
              <label for="materialUnitPrice" class="form-label text-center"
                >Unit Price(₹)</label
              >
              <input
                type="text"
                class="form-control text-right"
                id="materialUnitPrice"
                v-model="newMaterial.unitPrice"
              />
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button
              v-if="materialId"
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="UpdateMaterial"
            >
              Update
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary"
              @click="addMaterial"
              data-bs-dismiss="modal"
            >
              Add Material
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    materialId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      MaterialId: this.materialId,
      newMaterial: {
        materialName: "",
        discount: "",
        unitPrice: "",
      },
    };
  },
  mounted() {
    // Fetch material details when the component is mounted
    if (this.materialId !== null) {
      this.fetchMaterialDetails();
    }
  },
  watch: {
    materialId(newValue) {
      if (newValue) {
        this.MaterialId = newValue;
        this.fetchMaterialDetails();
      } else {
        this.newMaterial = {};
      }
    },
  },
  methods: {
    async fetchMaterialDetails() {
      try {
        // Make API call to fetch material details by materialId
        const response = await axios.get(
          `${this.$apiEndPoint}/materialLibrary/${this.MaterialId}`
        );
        // Set fetched data to newMaterial object to pre-fill input fields
        this.newMaterial = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async UpdateMaterial() {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/materialLibrary/${this.MaterialId}`,
          this.newMaterial
        );
        this.$emit("material-added", response.data);
        this.$emit("feachData");
        this.fetchData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async addMaterial() {
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/materialLibrary`,
          this.newMaterial
        );
        this.$emit("material-added", response.data);
        this.$emit("feachData");
        // Clear the form fields after adding the material
        this.clearForm();

        // Close the modal if needed
        // Add your modal close logic here
      } catch (error) {
        this.$handleError(error);
      }
    },
    clearForm() {
      // Clear the form fields
      this.newMaterial.materialName = "";
      this.newMaterial.discount = "";
      this.newMaterial.unitPrice = "";
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
label {
  font-weight: 500;
  color: black;
}
</style>
