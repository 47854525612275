<template>
  <div
    v-if="this.$route.params.estimateIndex"
    style="overflow: Scroll; max-height: 800px; margin-bottom: 100px"
  >
    <div class="pdf">
      <div class="main" ref="printContent">
        <table>
          <caption class="d-none">
            Table
          </caption>
          <tr style="display: flex; justify-content: space-between">
            <td
              class="date"
              style="font-weight: 500; font-size: 13px; text-align: left; width: 60%"
            >
              <p
                style="
                  padding: 0;
                  margin: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span style="flex: 1; text-align: left">Client Name</span>
                <span style="flex: 0.2; text-align: center">:</span>
                <span style="flex: 2; text-align: left">{{
                  routeParams.clientName
                }}</span>
              </p>
              <p
                style="
                  padding: 0;
                  margin: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span style="flex: 1; text-align: left">Contact No</span>
                <span style="flex: 0.2; text-align: center">:</span>
                <span style="flex: 2; text-align: left">{{ routeParams.contactNo }}</span>
              </p>
              <p
                style="
                  padding: 0;
                  margin: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span style="flex: 1; text-align: left">Email ID</span>
                <span style="flex: 0.2; text-align: center">:</span>
                <span style="flex: 2; text-align: left">{{ routeParams.email }}</span>
              </p>
              <p
                style="
                  padding: 0;
                  margin: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span style="flex: 1; text-align: left">Property Address</span>
                <span style="flex: 0.2; text-align: center">:</span>
                <span style="flex: 2; text-align: left">{{ routeParams.address }}</span>
              </p>
              <p
                style="
                  padding: 0;
                  margin: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span style="flex: 1; text-align: left">Estimate Number</span>
                <span style="flex: 0.2; text-align: center">:</span>
                <span style="flex: 2; text-align: left">{{
                  this.fetchedEstimateData.estimateId
                }}</span>
              </p>
              <p
                style="
                  padding: 0;
                  margin: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span style="flex: 1; text-align: left">Date</span>
                <span style="flex: 0.2; text-align: center">:</span>
                <span style="flex: 2; text-align: left">{{ routeParams.date }}</span>
              </p>
            </td>
            <td
              class="sign"
              v-if="companyLogo"
              style="font-weight: 500; font-size: 13px; text-align: left"
            >
              <img
                v-if="$CompanyLogoUrl"
                :src="$CompanyLogoUrl"
                alt="Company Logo"
                id="companylogoInput"
              />
              <br />
              <span v-if="companyDetails">
                <span style="font-weight: 600">{{ companyDetails.companyName }}</span>
                <br />
                <span style="font-size: 14px">
                  <span>
                    {{ companyDetails.address }} {{ companyDetails.city }}
                    {{ companyDetails.state }}
                  </span>
                </span>
                <br />
                <span style="font-size: 14px">
                  {{ companyDetails.contactNumber }}
                </span>
                <br />
                <span style="font-size: 14px">
                  {{ companyDetails.companyEmail }}
                </span>
              </span>
            </td>
          </tr>
        </table>

        <table class="EstimateLineItems" style="margin-top: 20px">
          <caption class="d-none">
            Estimate table
          </caption>
          <thead>
            <tr
              style="
                background-color: #f4f4f4;
                font-size: 10px;
                text-align: center;
                padding: 5px;
              "
            >
              <th style="text-align: center">SL. NO</th>
              <th style="text-align: center; width: 20%">Description</th>
              <th style="text-align: center">
                Width ({{ this.fetchedEstimateData.unitType }})
              </th>
              <th style="text-align: center">
                Height ({{ this.fetchedEstimateData.unitType }})
              </th>
              <th style="text-align: center">Qty</th>
              <th style="text-align: center">Unit</th>
              <th style="text-align: center" v-if="!this.$route.params.otherteam">
                Rate
              </th>
              <th style="text-align: center" v-if="!this.$route.params.otherteam">
                Discount(%)
              </th>
              <th style="text-align: center" v-if="!this.$route.params.otherteam">
                GST(%)
              </th>
              <th style="text-align: center" v-if="!this.$route.params.otherteam">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in items" :key="index">
              <tr style="background-color: #ccc" class="PrintItemHeaderOfThings">
                <td
                  colspan="5"
                  style="
                    text-align: left;
                    padding: 3px;
                    font-weight: 700;
                    font-size: 14px;
                  "
                >
                  {{ item.header }}
                </td>
                <td
                  colspan="5"
                  style="text-align: right; padding: 3px 10px 3px 3px; font-size: 14px"
                >
                  {{ $formatToIndianCurrency(calculateTotalAmountofItem(item)) }}
                </td>
              </tr>

              <tr
                v-for="(row, rowIndex) in item.rows"
                :key="rowIndex"
                style="font-size: 12px"
              >
                <td style="text-align: center" class="pb-3">{{ rowIndex + 1 }}</td>
                <td style="text-align: left; width: 20%">
                  <pre class="EstimateDescription">
                      {{ row.description }}
                    </pre
                  >
                </td>
                <td style="text-align: right">{{ row.width }}</td>
                <td style="text-align: right">{{ row.height }}</td>
                <td style="text-align: right">{{ row.quantity }}</td>
                <td style="text-align: center">{{ row.unitType }}</td>
                <td style="text-align: right" v-if="!this.$route.params.otherteam">
                  {{ row.rate }}
                </td>
                <td style="text-align: right" v-if="!this.$route.params.otherteam">
                  {{ row.discount }}
                </td>
                <td style="text-align: right" v-if="!this.$route.params.otherteam">
                  {{ row.GSTpercentage }}
                </td>
                <td style="text-align: right" v-if="!this.$route.params.otherteam">
                  {{ $formatToIndianCurrency(row.amount) }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <table style="margin-top: 10px">
          <caption class="d-none">
            Table
          </caption>

          <tbody style="font-size: 12px; font-weight: 500">
            <tr>
              <td style="padding: 0">Total Amount (Excluding GST):</td>
              <td style="text-align: right; padding: 0">
                {{ $formatToIndianCurrency(fetchedEstimateData.total) }}
              </td>
            </tr>
            <tr v-for="(detail, index) in GSTgrouping" :key="index" style="padding: 0">
              <td class="info" style="padding: 0">
                CGST ({{ detail.gst / 2 }}%):<br />
                SGST ({{ detail.gst / 2 }}%):<br />
              </td>
              <td class="amount" style="text-align: right; padding: 0">
                {{ $formatToIndianCurrency((detail.amount / 2).toFixed(2)) }}<br />
                {{ $formatToIndianCurrency((detail.amount / 2).toFixed(2)) }}<br />
              </td>
            </tr>

            <tr>
              <td style="padding: 0">Grand Total (Including GST):</td>
              <td style="text-align: right; padding: 0">
                {{ $formatToIndianCurrency(this.fetchedEstimateData.grandTotal) }}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="blackBgColor" v-if="routeParams.customerNote">
          <p class="HeaderOfBillItem">Customer Note</p>
        </div>
        <pre v-if="routeParams.customerNote">{{ routeParams.customerNote }}</pre>

        <div class="blackBgColor">
          <p class="HeaderOfBillItem">Bank Details</p>
        </div>
        <table>
          <caption class="d-none">
            Table
          </caption>
          <tr class="d-none">
            <th></th>
          </tr>
          <tr>
            <td class="date" style="font-size: 12px; text-align: left; font-weight: 600">
              <p
                style="
                  padding: 0;
                  margin: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span style="flex: 0.4; text-align: left">Account Name</span>
                <span style="flex: 0.2; text-align: center">:</span>
                <span style="flex: 2; text-align: left">{{
                  fetchedEstimateData["bankDetails.accountName"]
                }}</span>
              </p>
              <p
                style="
                  padding: 0;
                  margin: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span style="flex: 0.4; text-align: left"> Account Number</span>
                <span style="flex: 0.2; text-align: center">:</span>
                <span style="flex: 2; text-align: left">
                  {{ fetchedEstimateData["bankDetails.accountNumber"] }}</span
                >
              </p>
              <p
                style="
                  padding: 0;
                  margin: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span style="flex: 0.4; text-align: left"> Bank Name</span>
                <span style="flex: 0.2; text-align: center">:</span>
                <span style="flex: 2; text-align: left">
                  {{ fetchedEstimateData["bankDetails.bankName"] }}</span
                >
              </p>
              <p
                style="
                  padding: 0;
                  margin: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span style="flex: 0.4; text-align: left"> IFSC Code</span>
                <span style="flex: 0.2; text-align: center">:</span>
                <span style="flex: 2; text-align: left">
                  {{ fetchedEstimateData["bankDetails.IFSC"] }}</span
                >
              </p>
            </td>
          </tr>
        </table>

        <div class="col-md-12 mt-2">
          <div v-for="section in EstimateSectionData" :key="section.id">
            <div class="blackBgColor">
              <p class="HeaderOfBillItem">{{ section.estimateSectionHeader }}</p>
            </div>

            <section class="p-1">
              <pre>{{ section.estimateSectionContent }}</pre>
            </section>
          </div>
        </div>

        <table>
          <caption class="d-none">
            Table
          </caption>
          <tr>
            <td class="date" style="padding-left: 0px; font-weight: 600; font-size: 14px">
              Thanking you,<br />
              Regards<br />
              {{ fetchedEstimateData["employees.firstName"] }}
              {{ fetchedEstimateData["employees.lastName"] }}<br />
              {{ fetchedEstimateData["employees.mobileNumber"] }}<br />
              {{ fetchedEstimateData["employees.employeeEmailId"] }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div v-else style="overflow: Scroll; max-height: 800px; margin-bottom: 100px">
    <div class="pdf">
      <div class="main" ref="printContent">
        <div>
          <header class="salesOrderHeaderThings">
            <div
              class="col"
              style="
                font-size: 25px;
                color: #000000;
                width: 20%;
                text-align: left;
                font-weight: bold;
              "
            >
              Sales Order
            </div>
            <div class="col">
              <div style="font-weight: 400">
                <span>Date</span><br />
                <span>{{ fetchedSalesOrderData.salesOrderDate }}</span>
              </div>
            </div>
            <div class="col">
              <div style="font-weight: 400">
                <span>Sales Order No.</span> <br />
                <span>{{ fetchedSalesOrderData.salesOrderNumber }}</span>
              </div>
            </div>
            <div class="col">
              <div style="font-weight: 400">
                <span>Customer No.</span> <br />
                <span>{{ fetchedSalesOrderData["customers.customerNumber"] }}</span>
              </div>
            </div>
          </header>

          <div class="salesOrderHeaderDetails">
            <section class="salesOrderIssuedBy">
              <h6 class="text-nowrap">Issued By</h6>
              <span class="bodyText">
                <p style="font-weight: 800; font-size: 12px">
                  {{ fetchedSalesOrderData["company.companyName"] }}
                </p>
                <p>{{ fetchedSalesOrderData["company.address"] }}</p>
                <p>
                  {{ fetchedSalesOrderData["company.city"] }}
                  {{ fetchedSalesOrderData["company.state"] }}
                </p>
                <p>{{ fetchedSalesOrderData["company.contactNumber"] }}</p>
                <p>{{ fetchedSalesOrderData["company.companyEmail"] }}</p>
              </span>
            </section>
            <section class="salesOrderBillTo">
              <h6 class="text-nowrap">Bill To</h6>
              <span class="bodyText">
                <p style="font-weight: 800; font-size: 12px">
                  {{ fetchedSalesOrderData["billingAddress.name"] }}
                </p>
                <p>{{ fetchedSalesOrderData["billingAddress.address"] }}</p>
                <p>
                  {{ fetchedSalesOrderData["billingAddress.city"] }}
                  {{ fetchedSalesOrderData["billingAddress.state"] }}
                </p>
                <p>{{ fetchedSalesOrderData["billingAddress.contactNo"] }}</p>
                <p>{{ fetchedSalesOrderData["billingAddress.email"] }}</p>
              </span>
            </section>
            <section class="salesOrderShipTo">
              <h6 class="text-nowrap">Ship To</h6>
              <span class="bodyText">
                <p style="font-weight: 800; font-size: 12px">
                  {{ fetchedSalesOrderData["shippingAddress.name"] }}
                </p>
                <p>{{ fetchedSalesOrderData["shippingAddress.address"] }}</p>
                <p>
                  {{ fetchedSalesOrderData["shippingAddress.city"] }}
                  {{ fetchedSalesOrderData["shippingAddress.state"] }}
                </p>
                <p>{{ fetchedSalesOrderData["shippingAddress.contactNo"] }}</p>
                <p>{{ fetchedSalesOrderData["shippingAddress.email"] }}</p>
              </span>
            </section>
          </div>

          <table class="EstimateLineItems">
            <caption class="d-none">
              Estimate table
            </caption>
            <thead>
              <tr style="background-color: #f4f4f4; font-size: 10px; text-align: center">
                <th style="text-align: center">SL. NO</th>
                <th style="text-align: center; width: 30%">Description</th>

                <th style="text-align: center">Qty</th>
                <th style="text-align: center">Unit</th>
                <th style="text-align: center">Rate</th>
                <th style="text-align: center">Discount</th>
                <th style="text-align: center">GST</th>
                <th style="text-align: center">Amount</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in items" :key="index">
                <tr style="background-color: #ccc" class="PrintItemHeaderOfThings">
                  <td
                    colspan="5"
                    style="
                      text-align: center;
                      padding: 3px;
                      padding-left: 250px;
                      font-size: 14px;
                    "
                  >
                    {{ item.header }}
                  </td>
                  <td
                    colspan="5"
                    style="text-align: right; padding: 3px 10px 3px 3px; font-size: 14px"
                  >
                    {{ $formatToIndianCurrency(calculateTotalAmountofItem(item)) }}
                  </td>
                </tr>

                <tr
                  v-for="(row, rowIndex) in item.rows"
                  :key="rowIndex"
                  style="font-size: 12px"
                >
                  <td style="text-align: center">{{ rowIndex + 1 }}</td>
                  <td style="text-align: left; width: 30%">
                    <pre class="EstimateDescription">
                      {{ row.description }}
                    </pre>
                  </td>

                  <td style="text-align: right">{{ row.quantity }}</td>
                  <td style="text-align: center">{{ row.unitType }}</td>
                  <td style="text-align: right">{{ row.unitPrice }}</td>
                  <td style="text-align: right">{{ row.discount }}</td>
                  <td style="text-align: right">{{ row.GSTpercentage }}</td>
                  <td style="text-align: right">
                    {{ $formatToIndianCurrency(row.amount) }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>

          <table style="margin-top: 15px">
            <caption class="d-none">
              Table
            </caption>
            <tbody style="font-size: 12px; font-weight: 500">
              <tr>
                <td style="padding: 0">Total Amount (Excluding GST):</td>
                <td style="text-align: right; padding: 0">
                  {{ $formatToIndianCurrency(fetchedSalesOrderData.total) }}
                </td>
              </tr>
              <tr v-for="(detail, index) in GSTgrouping" :key="index" style="padding: 0">
                <td class="info" style="padding: 0">
                  CGST ({{ detail.gst / 2 }}%):<br />
                  SGST ({{ detail.gst / 2 }}%):<br />
                </td>
                <td class="amount" style="text-align: right; padding: 0">
                  {{ $formatToIndianCurrency((detail.amount / 2).toFixed(2)) }}<br />
                  {{ $formatToIndianCurrency((detail.amount / 2).toFixed(2)) }}<br />
                </td>
              </tr>

              <tr>
                <td style="padding: 0">Grand Total (Including GST):</td>
                <td style="text-align: right; padding: 0">
                  {{ $formatToIndianCurrency(this.fetchedSalesOrderData.grandTotal) }}
                </td>
              </tr>
            </tbody>
          </table>

          <table style="margin-top: 20px">
            <caption class="d-none">
              Table
            </caption>
            <tr class="d-flex justify-content-between">
              <td
                class="date"
                style="padding-left: 0px; font-weight: 600; font-size: 14px"
              >
                Thanking you,<br />
                Regards<br />
                {{ fetchedSalesOrderData["employees.firstName"] }}
                {{ fetchedSalesOrderData["employees.lastName"] }}<br />
                {{ fetchedSalesOrderData["employees.mobileNumber"] }}<br />
                {{ fetchedSalesOrderData["employees.employeeEmailId"] }}
              </td>
              <td
                class="text-start"
                style="padding-left: 0px; font-weight: 600; font-size: 14px"
              >
                <span class="inputWithImage">
                  <img
                    v-if="$CompanyLogoUrl"
                    :src="$CompanyLogoUrl"
                    alt="Company Logo"
                    id="companylogoInput"
                  />
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import html2pdf from "html2pdf.js";
export default {
  name: "PrintEstimate",
  components: {},
  props: {
    estimateIndex: {
      type: String,
      required: true,
    },
    salesOrderIndex: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      companyLogo: {},
      CompanyId: this.$LocalUser.user.companyId,
      routeParams: {
        clientName: "",
        propertyName: "",
        contactNo: "",
        salesPerson: "",
        email: "",
        estimateId: this.$route.params.EstimateId || "",
        estimateIndex: this.$route.params.estimateIndex || "",
        date: "",
        address: "",
        customerNote: "",
        status: "",
        widthUnitType: "",
        heightUnitType: "",
      },
      SalesOrderId: this.$route.params.SalesOrderIndex || "",
      items: [],
      bankDetails: {},
      EstimateSectionData: [],
      fetchedEstimateData: {},
      fetchedSalesOrderData: {},
      GSTgrouping: [],
      companyDetails: {},
      GSTOptions: [5, 12, 18, 28],
    };
  },
  mounted() {
    this.routeParams.estimateIndex = this.$route.params.estimateIndex;
    if (this.$route.params.estimateIndex) {
      this.fetchEstimateData().then(() => {
        if (this.fetchedEstimateData) {
          // Use setTimeout to ensure the DOM is updated before accessing it
          setTimeout(() => {
            const fileName = `Estimate (${this.fetchedEstimateData.estimateId}).pdf`;
            const contentToPrint = this.$refs.printContent;
            html2pdf(contentToPrint, {
              filename: fileName,
              margin: [10, 10, 10, 10], // top, left, bottom, right
              html2canvas: { scale: 2 },
              jsPDF: { format: "a4", orientation: "portrait" },
            });
          }, 0);
        }
      });
    } else if (this.$route.params.salesOrderIndex) {
      this.SalesOrderId = this.$route.params.salesOrderIndex;
      this.fetchSalesData().then(() => {
        if (this.fetchedSalesOrderData) {
          // Preload the company logo image

          setTimeout(() => {
            const contentToPrint = this.$refs.printContent;
            const fileName = `SalesOrder (${this.fetchedSalesOrderData.salesOrderNumber}).pdf`;
            html2pdf(contentToPrint, {
              filename: fileName,
              margin: [10, 10, 10, 10], // top, left, bottom, right
              html2canvas: { scale: 2 },
              jsPDF: { format: "a4", orientation: "portrait" },
            });
          }, 0);
        }
      });
    } else {
      //console.log("Id Is not present")
    }
  },

  methods: {
    featchCompanyLogo(CompanyId) {
      const PayLoad = {
        entityId: CompanyId,
        fileCategory: "logo",
        moduleName: "company",
      };

      // Make API request to fetch employee profile photo URL
      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          this.CompanyLogo = Object.values(response.data)[0];
        })
        .catch(() => {});
    },

    async fetchSalesData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/salesOrder/${this.$route.params.salesOrderIndex} `
        );
        this.fetchedSalesOrderData = response.data;
        this.GSTgrouping = response.data.GSTgrouping;
        // this.companyDetails = response.data.companyDetails;
        this.companyLogo = response.data.companyLogo;

        if (this.fetchedSalesOrderData?.lineItems) {
          this.items = this.fetchedSalesOrderData.lineItems.map((item) => ({
            header: item.header,
            rows: item.Rows.map((row) => ({
              ...row,
              GSTpercentage: row.GSTpercentage, // Assign GSTpercentage directly from response
            })),
          }));
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchEstimateData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/estimates/${this.routeParams.estimateIndex}`
        );
        this.fetchedEstimateData = response.data;
        this.GSTgrouping = response.data.GSTgrouping;
        this.companyDetails = response.data.companyDetails;
        this.companyLogo = response.data.companyLogo;

        this.setEstimateSectionData();
        this.setCustomerOrLeadData();
        this.setAddressData();
        this.setLineItemsAndGSTDetails();
      } catch (error) {
        this.$handleError(error);
      }
    },

    setEstimateSectionData() {
      this.EstimateSectionData = this.fetchedEstimateData.estimateSectionData;
      this.routeParams.customerNote = this.fetchedEstimateData.customerNote;
      this.routeParams.propertyName = this.fetchedEstimateData["property.name"];
      this.routeParams.propertyId = this.fetchedEstimateData["property.id"];
      this.routeParams.salesPerson = this.fetchedEstimateData.salesPersonId || "";
      this.routeParams.date = this.fetchedEstimateData.date || "";
    },

    setCustomerOrLeadData() {
      if (this.fetchedEstimateData["customers.name"]) {
        this.routeParams.clientName = this.fetchedEstimateData["customers.name"];
        this.routeParams.contactNo =
          this.fetchedEstimateData["customers.contactNo"] || "";
        this.routeParams.email = this.fetchedEstimateData["customers.email"];
      } else {
        this.routeParams.clientName = this.fetchedEstimateData["leads.name"];
        this.routeParams.contactNo = this.fetchedEstimateData["leads.contactNo"] || "";
        this.routeParams.email = this.fetchedEstimateData["leads.email"];
      }
    },

    setAddressData() {
      if (this.fetchedEstimateData["addresses.address"]) {
        this.routeParams.address = `${
          this.fetchedEstimateData["addresses.address"] ?? ""
        } ${this.fetchedEstimateData["addresses.city"] ?? ""} ${
          this.fetchedEstimateData["addresses.state"] ?? ""
        }`;
      } else {
        this.routeParams.address = `${
          this.fetchedEstimateData["leadAddresses.address"] ?? ""
        } ${this.fetchedEstimateData["leadAddresses.city"] ?? ""} ${
          this.fetchedEstimateData["leadAddresses.state"] ?? ""
        }`;
      }
    },

    setLineItemsAndGSTDetails() {
      if (this.fetchedEstimateData.lineItems) {
        this.items = this.fetchedEstimateData.lineItems.map((item) => ({
          header: item.header,
          rows: item.Rows.map((row) => ({
            ...row,
            GSTpercentage: row.GSTpercentage,
          })),
        }));
      }
    },

    calculateTotalAmountofItem(item) {
      let totalAmount = 0;
      item.rows.forEach((row) => {
        totalAmount += parseFloat(row.amount || 0);
      });
      return totalAmount;
    },
  },
};
</script>
/* CSS for PDF content */
<style scoped>
/* CSS for PDF content */
.pdf {
  width: 21cm;
  height: 29.7cm;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  font-size: 12px;
  overflow-y: auto;
  /* Change to 'scroll' if you always want the scrollbar to be visible */
}

.pdf::-webkit-scrollbar {
  display: none;
}

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
}

.EstimateLineItems th,
.EstimateLineItems td {
  padding: 3px;

  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Flex container for header sections */
.headerName {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  background-color: #ccc;
  height: 30px;
  margin: 5px 0;
  font-size: 16px;
  font-weight: bold;
}

/* Header of bill item */
.HeaderOfBillItem {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  margin: 10px auto;
  padding: 5px;
  color: #ffffff;
}

/* Styling the customer note and bank details */
pre {
  font-size: 12px;
  color: #000000;
  font-family: "Inter", sans-serif;
  word-break: break-word;
  white-space: pre-wrap;
  width: fit-content;
}

.EstimateDescription {
  font-size: 12px;
  color: #000000;
  font-family: "Inter", sans-serif;
  word-break: break-all;
  white-space: wrap;
}
/* Thanking you and regards section */
.footer {
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}

.sign {
  width: 30%;
  border: 0px;
}

#companylogoInput {
  height: 60px;
  width: fit-content;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  max-width: 100%;
}

.salesOrderHeaderThings {
  display: flex;
  border-bottom: 5px solid #000000;
  align-items: center;
  justify-content: space-between;
  padding: 0.5% 1%;
  margin: 0 1%;
}

.salesOrderHeaderThings div:nth-child(2),
.salesOrderHeaderThings div:nth-child(3),
.salesOrderHeaderThings div:nth-child(4) {
  width: 20%;
  font-size: 14px;
  color: #000000;

  margin-left: 10px;
}

.salesOrderHeaderDetails {
  display: flex;
  align-items: flex-start;
  border-radius: 20px;
  margin: 0.2%;
  padding: 1% 5%;
  height: 25%;
  width: 100%;
  flex-direction: row;
  overflow-x: scroll;
}

.salesOrderHeaderDetails::-webkit-scrollbar {
  display: none;
}

.salesOrderHeaderDetails section {
  width: 30%;
  margin: 0% 5%;
  min-height: 150px;
  max-height: auto;
}

.salesOrderHeaderDetails section h6 {
  color: #000000;
  text-align: left;
  font-size: 16px;
  white-space: nowrap;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
}

.salesOrderHeaderDetails section span {
  text-align: left;
  font-size: 16px;
  color: #000000;

  margin: 0;
  padding: 0;
}

.salesOrderHeaderDetails section span p {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.salesOrderFooterDetails {
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin: 0.2%;
  height: 25%;
  overflow-x: scroll;
  width: 100%;
}

.SalesPersonDetail {
  color: #000000;
  font-weight: bold;
}

/* End of PDF content CSS */
</style>
