<template>
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="FormatTable" v-if="showUnclaimedList">
        <div class="row headerOfTable d-flex">
          <div class="d-flex justify-content-between align-content-center">
            <div class="d-flex">
              <div class="d-flex text-nowrap SubheadingUnderLine">
                <h5>Contractors</h5>
                <a href="/CreateContractor" class="ms-3">
                  <img src="../../../assets/icons/blueAddicon.png" alt="" id="addicon" />
                </a>
              </div>
            </div>
            <div class="d-flex justify-content-around align-items-center">
              <div class="ImportExport">
                <div class="row" @click="importData">
                  <div class="col-md-4 tableTextEvenRow">Import</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/import.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>
              <div class="ImportExport">
                <div class="row" @click="exportToCSV">
                  <div class="col-md-4 tableTextEvenRow">Export</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/export.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>
              <div class="">
                <img
                  src="../../../assets/icons/archive.png"
                  alt=""
                  id="ArchiveIcon"
                  @click="handleArchiveClick"
                />
              </div>
              <div class="searchboxOfTable">
                <div class="search-box">
                  <img
                    src="../../../assets/icons/search.png"
                    alt="Search Icon"
                    id="SerchIconOfTable"
                  />
                  <input type="text" v-model="searchString" @input="searchLeads" />
                </div>
                <span>
                  <img
                    src="../../../assets/icons/filter.png"
                    alt="Search Icon"
                    id="FilterIconOfTable"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrapper WEB">
          <table class="tableFormat">
            <caption class="d-none">
              Contractor List
            </caption>
            <thead>
              <tr>
                <th>
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectAll"
                      @change="selectAllRows"
                    />
                  </div>
                  <span id="btnGroupDrop1" data-bs-toggle="dropdown">
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      id="blackThreeDots"
                    />
                  </span>
                  <div class="dropdown-menu ms-3 text-black p-2">
                    <p @click="ArchiveLeads('Archive')">Archive</p>
                    <p @click="deleteLead('ArchiveDelete')">Delete</p>
                  </div>
                </th>
                <th>Contractor Id</th>
                <th>Contractor Name</th>
                <th>Contact No</th>
                <th>Email ID</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in displayedData" :key="index">
                <td>
                  <div class="custom-checkbox">
                    <input type="checkbox" v-model="selectedCustomers" :value="data.id" />
                  </div>
                  <span data-bs-toggle="dropdown">
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      id="blackThreeDots"
                    />
                  </span>
                  <div class="dropdown-menu ms-3 text-black p-2">
                    <p>Export</p>
                    <p @click="ArchiveLead(data, index)">Archive</p>
                    <p @click="DeleteLead(data, index)">Delete</p>
                  </div>
                </td>
                <td @click="openModal(data.id)">{{ data.name }}</td>
                <td>{{ data.contractorNumber }}</td>
                <td>{{ data.contactNo }}</td>
                <td>{{ data.email }}</td>
                <td v-if="data.getCategories && data.getCategories.length > 0">
                  {{ data.getCategories[0]["onSiteCategory.categoryName"] }}
                </td>
                <td v-else>N/A</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-wrapper MOBILE">
          <table class="tableFormat">
            <caption class="d-none">
              Table Format
            </caption>
            <thead>
              <tr>
                <th>
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectAll"
                      @change="selectAllRows"
                    />
                  </div>
                  <span id="btnGroupDrop1" data-bs-toggle="dropdown">
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      id="blackThreeDots"
                    />
                  </span>
                  <div class="dropdown-menu ms-3 text-black p-2">
                    <p @click="ArchiveLeads('Archive')">Archive</p>
                    <p @click="deleteLead('ArchiveDelete')">Delete</p>
                  </div>
                </th>
                <th>Contractor Name</th>
                <th>Contact No</th>
                <th>Category</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in displayedData" :key="index">
                <td>
                  <div class="custom-checkbox">
                    <input type="checkbox" v-model="selectedCustomers" :value="data.id" />
                  </div>
                  <span data-bs-toggle="dropdown">
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      id="blackThreeDots"
                    />
                  </span>
                  <div class="dropdown-menu ms-3 text-black p-2">
                    <p>Export</p>
                    <p @click="ArchiveLead(data, index)">Archive</p>
                    <p @click="DeleteLead(data, index)">Delete</p>
                  </div>
                </td>
                <td @click="openModal(data.id)" class="ellipsis">{{ data.name }}</td>
                <td>{{ data.contractorNumber }}</td>
                <td v-if="data.getCategories && data.getCategories.length > 0">
                  {{ data.getCategories[0]["onSiteCategory.categoryName"] }}
                </td>
                <td v-else>N/A</td>
                <td>
                  <img
                    src="../../../assets/icons/info.png"
                    alt=""
                    srcset=""
                    data-bs-target="#tableDetailModal"
                    data-bs-toggle="modal"
                    style="width: 18px; height: 18px"
                    @click="showDetails(data)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginationOfTable">
          <div class="pageControlsTable">
            <img
              src="../../../assets/icons/blueLeftarrow.png"
              @click="goToPreviousPage"
              :disabled="!hasPreviousPage"
              alt="Prev"
            />
            <div class="page-numbers">
              <span
                v-for="pageNumber in pagination"
                :key="pageNumber"
                @click="goToPage(pageNumber)"
                :class="{ 'current-page': pageNumber === currentPage }"
                >{{ pageNumber }}</span
              >
            </div>
            <img
              src="../../../assets/icons/blueRightarrow.png"
              @click="goToNextPage"
              :disabled="!hasNextPage"
              alt="Next"
            />
          </div>
          <div class="entries-info">
            <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
          </div>
        </div>
      </div>
      <div class="FormatTable" v-if="showArchiveList">
        <div class="row headerOfTable d-flex">
          <div class="d-flex justify-content-between align-content-center">
            <div class="d-flex">
              <div class="d-flex text-nowrap SubheadingUnderLine">
                <h5>Contractors</h5>
              </div>
            </div>
            <div class="d-flex justify-content-around align-items-center">
              <div class="ImportExport">
                <div class="row" @click="importData">
                  <div class="col-md-4 tableTextEvenRow">Import</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/import.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>
              <div class="ImportExport">
                <div class="row" @click="exportToCSV">
                  <div class="col-md-4 tableTextEvenRow">Export</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/export.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>
              <div class="">
                <img
                  src="../../../assets/icons/unarchive.png"
                  alt=""
                  id="ArchiveIcon"
                  @click="handleArchiveClick"
                />
              </div>
              <div class="searchboxOfTable">
                <div class="search-box">
                  <img
                    src="../../../assets/icons/search.png"
                    alt="Search Icon"
                    id="SerchIconOfTable"
                  />
                  <input type="text" v-model="searchString" @input="searchLeads" />
                </div>
                <span>
                  <img
                    src="../../../assets/icons/filter.png"
                    alt="Search Icon"
                    id="FilterIconOfTable"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="table-wrapper">
          <table class="tableFormat">
            <caption class="d-none">
              Archive ContractorList
            </caption>
            <thead>
              <tr>
                <th class="">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectAll"
                      @change="selectAllArchiveRows"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-2"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="ArchiveLeads('UnArchive')">UnArchive</p>
                    <p @click="deleteLead('UnArchiveDelete')">Delete</p>
                  </div>
                </th>
                <th class="tableHeaderHeading">Name</th>
                <th class="tableHeaderHeading">Customer ID</th>
                <th class="tableHeaderHeading">Contact No</th>
                <th class="tableHeaderHeading">Email ID</th>
                <th class="tableHeaderHeading">Property Address</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in displayedArchiveData" :key="index">
                <td class="bodyText">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectedCustomers"
                      :value="data.id"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-2"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="ArchiveLead(data, index)">UnArchive</p>
                    <p @click="DeleteLead(data, index)">Delete</p>
                  </div>
                </td>
                <td
                  @click="openModal(data.id)"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3"
                  data-bs-whatever="@mdo"
                >
                  {{ data.name }}
                </td>
                <td class="text-left">{{ data.contractorNumber }}</td>
                <td class="text-left">{{ data.contactNo }}</td>
                <td>{{ data.email }}</td>
                <td>{{ data["onSiteCategory.categoryName"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginationOfTable">
          <div class="pageControlsTable">
            <img
              src="../../../assets/icons/blueLeftarrow.png"
              @click="goToArchivePreviousPage"
              :disabled="!hasArchivePreviousPage"
              alt="Prev"
            />
            <div class="page-numbers">
              <span
                v-for="pageNumber in paginationArchive"
                :key="pageNumber"
                :class="{
                  'page-number': true,
                  'current-page': pageNumber === currentPage,
                }"
                @click="goToPage(pageNumber)"
                >{{ pageNumber }}</span
              >
            </div>

            <img
              src="../../../assets/icons/blueRightarrow.png"
              @click="goToArchiveNextPage"
              :disabled="!hasArchiveNextPage"
              alt="Next"
            />
          </div>

          <div class="entries-info">
            <span class="entries-text"
              >Entries: {{ displayedArchiveData.length }} Rows</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <TableDetailsModal :details="details" />
</template>

<script>
import axios from "axios";
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import TableDetailsModal from "../../Modal/TableDetailsModal.vue"; // Import the modal
let pageFlag = true;

export default {
  name: "ContracatorsList",
  components: {
    Header,
    Nav,
    TableDetailsModal,
  },
  data() {
    return {
      totalCount: null,
      totalArchiveCount: null,
      searchString: "",
      leadDropdownStates: {},
      tabledropdownEdits: false,
      showClaimedList: false,
      selectAll: false,
      TableData: [],
      ArchiveData: [],
      selectedCustomers: [],
      pageSize: 50,
      currentPage: 1,
      showUnclaimedList: true,
      showArchiveList: false,
      selectedEmployees: [],
      searchResults: [],
      leadTypePage: true,
      dropdownOpen: false,
      statusDropdownOpen: false,
      selectedLeadId: null,
      details: [],
    };
  },
  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.totalCount / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.totalArchiveCount / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.totalCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.totalArchiveCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      return this.TableData;
    },
    displayedArchiveData() {
      return this.ArchiveData;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    showDetails(rowData) {
      // Map row data to label-value pairs
      this.details = [
        { label: "Contractor Name", value: rowData.name },
        { label: "Contractor Id", value: rowData.contractorNumber },
        { label: "Contact No", value: rowData.contactNo },
        { label: "Email ID", value: rowData.email },
      ];
    },
    exportToCSV() {
      // Combine displayedData and displayedArchiveData based on your current view
      const allData = this.showArchiveList ? this.ArchiveData : this.TableData;

      // Extract only the fields you want to include in the CSV
      const filteredData = allData.map((data) => ({
        /*   Name: `${data.salutation} ${data.name}`, */
        Saturation: data.salutation,
        Name: data.name,
        "Customer ID": data.customerNumber,
        "Contact No": data.contactNo,
        "Email ID": data.email,
        "Property Address": data["addresses.address"],
      }));

      // Convert filtered data to CSV format
      const csvContent = this.convertToCSV(filteredData);

      // Trigger download
      this.downloadCSV(csvContent);
    },

    convertToCSV(data) {
      const headers = Object.keys(data[0]).join(",") + "\n";
      const csvData = data.map((item) => Object.values(item).join(",")).join("\n");
      return headers + csvData;
    },

    downloadCSV(csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    goToPreviousPage() {
      if (this.hasPreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToNextPage() {
      if (this.hasNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    goToArchivePreviousPage() {
      if (this.hasArchivePreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToArchiveNextPage() {
      if (this.hasArchiveNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    changePage(delta) {
      this.selectAll = false;
      this.lastPages = this.currentPage;
      this.currentPage += delta;
      this.fetchData();
      this.fetchArchiveData();
      this.selectedCustomers = [];
    },
    //footer pagination
    goToPage(pageNumber) {
      this.selectAll = false;
      this.currentPage = pageNumber;
      console.log(this.currentPage);
      this.fetchData();
      this.fetchArchiveData();
      this.selectedCustomers = [];
    },
    //featch all Unclaim lead Data
    SigleLeadThingstd(lead) {
      this.selectedLead = lead;
      this.leadDropdownStates[lead.id] = !this.leadDropdownStates[lead.id];
    },
    toggletabledrowpdown() {
      this.tabledropdownEdits = !this.tabledropdownEdits;
    },

    openModal(leadId) {
      console.log(this.selectedLeadId);
      this.selectedLeadId = leadId;
      this.$router.push({
        name: "UpdateContractor",
        params: { contractorId: this.selectedLeadId },
      });
    },

    handleArchiveClick() {
      this.fetchArchiveData();
      this.showUnclaimedList = !this.showUnclaimedList;
      this.showArchiveList = !this.showArchiveList;
    },
    async fetchData() {
      let searchData = {
        searchString: this.searchString,
        pageFlag,
      };

      const hasSearchData = searchData.searchString;
      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
      }
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/onSiteContractor/${this.currentPage}`,
          searchData
        );
        this.totalCount = response.data.count;
        this.TableData = response.data.rows;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchArchiveData() {
      let searchData = {
        searchString: this.searchString,
        pageFlag,
      };
      const hasSearchData = searchData.searchString;
      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
      }
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/onSiteContractor/${this.currentPage}/archived`,
          searchData
        );
        this.totalArchiveCount = response.data.count;
        this.ArchiveData = response.data.rows;
      } catch (error) {
        this.$handleError(error);
      }
    },
    //select and unselect checkbox function
    selectAllRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },
    selectAllArchiveRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedArchiveData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },

    // Method to delete the selected lead
    DeleteLead(data, index) {
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);
      axios
        .delete(`${this.$apiEndPoint}/onSiteContractor/deletContractors`, {
          data: { id: [data.id] },
        })
        // Pass an array with a single ID to match the API's expected format
        .then((response) => {
          console.log("Lead deleted successfully:", response.data);
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    ArchiveLead(data, index) {
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);
      console.log(this.ArchiveData);
      axios
        .put(`${this.$apiEndPoint}/onSiteContractor/archive`, { id: [data.id] })
        // Pass an array with a single ID to match the API's expected format
        .then((response) => {
          console.log("Lead deleted successfully:", response.data);
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    searchArchiveLeads() {
      pageFlag = true;
      this.fetchArchiveData();
    },
    //for Serch api for Unclaimleads
    searchLeads() {
      pageFlag = true;
      this.fetchData();
    },

    //for all selected lead Unclaim and delete api
    ArchiveLeads(action) {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      }
      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .put(`${this.$apiEndPoint}/onSiteContractor/archive`, payload)
        .then((response) => {
          console.log("Leads updated successfully:", response.data);

          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.selectAll = false;
          this.tabledropdownEdits = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    deleteLead(action) {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      }
      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .delete(`${this.$apiEndPoint}/onSiteContractor/deletContractors`, {
          data: payload,
        })
        .then((response) => {
          console.log("Leads deleted successfully:", response.data);
          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.selectAll = false;
          this.tabledropdownEdits = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //featch all Unclaim lead Data

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style scoped>
.FormatTable {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tableFormat:nth-child(2) {
  cursor: pointer;
}

.headerOfTable {
  width: 96%;
  padding: 0.5% 1%;
}

.table-wrapper {
  width: 100%;
  padding: 0 1%;
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100% - 74px);
  padding-bottom: 20px;
}

.tableFormat {
  width: 100%;
  border-collapse: collapse;
}
.tableFormat th:nth-child(1),
.tableFormat td:nth-child(1) {
  display: inline-flex;
  width: 50px !important;
  background-color: #ffffff;
}
.tableFormat thead {
  background-color: #bcbfc6;
  position: sticky;
  z-index: 99;
  top: 0;
}

.tableFormat thead tr th:not(:first-child) {
  background-color: #bcbfc6;
}
.tableFormat th,
.tableFormat td {
  padding: 8px;
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
}

#blackThreeDots {
  width: 15px;
  height: 14px;
  margin-left: 30%;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
}

.tableHeaderCheckbox,
.bodyCheckbox {
  width: 200px; /* Adjust width as needed */
}

.tableHeaderHeading {
  min-width: 150px; /* Minimum width to ensure readability */
  text-align: center; /* Center align headers */
}

.bodyText {
  text-align: center; /* Center align body text */
}

.custom-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
}
.table-wrapper
  .tableFormat
  tbody
  tr:not(:first-child):nth-child(even)
  td:not(:first-child) {
  background-color: #f8f8f8;
}
.ImportExport {
  visibility: hidden;
}

#addicon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

#ImportIcon {
  width: 20px;
  cursor: pointer;
}

#ArchiveIcon {
  width: 15px;
  cursor: pointer;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 20%;
}

#FilterIconOfTable {
  width: 20%;
  height: 5%;
  margin-left: 10%;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  text-align: left;
}
.FormatTable {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.headerOfTable {
  width: 100%;
  padding: 0.5% 1%;
}

.table-wrapper {
  width: 100%;
  padding: 0 1%;
  overflow-x: auto;
  height: calc(100% - 74px);
  padding-bottom: 20px;
}

.tableFormat {
  width: 100%;
  border-collapse: collapse;
}

.custom-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
}

/* Icons */
#addicon,
#ImportIcon,
#ArchiveIcon,
#SerchIconOfTable {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
#FilterIconOfTable {
  width: 20px;
  height: 17px;
  cursor: pointer;
}

#blackThreeDots {
  width: 15px;
  cursor: pointer;
}

.ImportExport {
  visibility: hidden;
}

/* Pagination */
.pageControlsTable img {
  width: 20px;
  cursor: pointer;
}

.page-numbers {
  display: inline-flex;
}

.page-number {
  margin: 0 5px;
  cursor: pointer;
}

.current-page {
  font-weight: bold;
  text-decoration: underline;
}

.entries-info {
  margin-top: 10px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .tableFormat th,
  .tableFormat td {
    font-size: 12px;
  }

  .table-wrapper {
    max-height: 90%;
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  .ImportExport {
    display: none;
  }

  .paginationOfTable {
    bottom: 1%;
  }

  #ArchiveIcon {
    margin: 0 5%;
    margin-left: 10px;
    margin-bottom: 6px;
  }
}
</style>
