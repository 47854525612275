<template>
  <div
    class="modal fade my-4"
    id="exampleModal1"
    tabindex="-1"
    aria-labelledby="exampleModal1Label"
    aria-hidden="true"
  >
    <div class="createLeadForm">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <!-- Change modal-dialog class to modal-lg for 50% width -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title btn btn-primary btn-sm" id="exampleModalLabel">
              Lead Details
            </h5>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row d-flex align-content-center">
              <div class="col-md-6">
                <div class="form-group row mt-1">
                  <label for="salutation" class="col-md-4 col-form-label text-nowrap"
                    >Name :</label
                  >
                  <div class="col-md-3">
                    <select
                      id="salutation"
                      class="form-control inputBg"
                      v-model="leadData.salutation"
                    >
                      <option value="">Select</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <!-- Gender-neutral title for transgender individuals -->
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div class="col-md-5">
                    <input
                      id="name"
                      type="text"
                      class="form-control inputBg"
                      v-model="leadData.name"
                      v-capitalize-first-letter
                    />
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label for="email" class="col-md-4 col-form-label text-nowrap"
                    >Email:</label
                  >
                  <div class="col-md-8">
                    <input
                      id="email"
                      type="email"
                      class="form-control inputBg"
                      v-model="leadData.email"
                    />
                    <small
                      v-if="leadData.email && !isValidEmail(leadData.email)"
                      class="text-danger"
                    >
                      Please enter a valid email address.
                    </small>
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label for="residential-address" class="col-md-4 col-form-label"
                    >Residential Address:</label
                  >
                  <div class="col-md-8">
                    <textarea
                      id="residential-address"
                      class="form-control p-2 inputBg"
                      style="min-height: 50px; vertical-align: top"
                      v-model="ResidentialData.address"
                    ></textarea>
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label
                    for="residential-city"
                    class="col-md-4 col-form-label text-nowrap"
                    >City:</label
                  >
                  <div class="col-md-8">
                    <input
                      id="residential-city"
                      type="text"
                      class="form-control inputBg"
                      v-model="ResidentialData.city"
                    />
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label
                    for="residential-state"
                    class="col-md-4 col-form-label text-nowrap"
                    >State:</label
                  >
                  <div class="col-md-8">
                    <input
                      id="residential-state"
                      type="text"
                      class="form-control inputBg"
                      v-model="ResidentialData.state"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row mt-1">
                  <label for="contact-no" class="col-md-4 col-form-label text-nowrap"
                    >Contact No:</label
                  >
                  <div class="col-md-8">
                    <input
                      id="contact-no"
                      type="tel"
                      class="form-control inputBg"
                      v-model="leadData.contactNo"
                      pattern="[0-9]{10}"
                    />
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label for="priority" class="col-md-4 col-form-label text-nowrap"
                    >Priority:</label
                  >
                  <div class="col-md-8">
                    <select
                      id="priority"
                      class="form-control inputBg"
                      v-model="leadData.priority"
                    >
                      <option value="">Select</option>
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                      <option value="High">High</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label for="property-address" class="col-md-4 col-form-label"
                    >Property Address:</label
                  >
                  <div class="col-md-8">
                    <textarea
                      id="property-address"
                      class="form-control p-2 inputBg"
                      style="min-height: 50px; vertical-align: top"
                      v-model="PropertyData.address"
                    ></textarea>
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label for="property-city" class="col-md-4 col-form-label text-nowrap"
                    >City:</label
                  >
                  <div class="col-md-8">
                    <input
                      id="property-city"
                      type="text"
                      class="form-control inputBg custom-outline"
                      v-model="PropertyData.city"
                    />
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label for="property-state" class="col-md-4 col-form-label text-nowrap"
                    >State:</label
                  >
                  <div class="col-md-8">
                    <input
                      id="property-state"
                      type="text"
                      class="form-control inputBg"
                      v-model="PropertyData.state"
                    />
                  </div>
                </div>
              </div>
            </div>
            <section class="subHeadingsOfLeads">
              <div
                class="mt-2 mb-2 p-1 subHeaderHeadingBgColor subHeadingsOfLead"
                style="border-radius: 5px"
              >
                <p
                  class="ms-2"
                  :class="{
                    'active-SubHeading': showOtherDetails,
                    'inactive-SubHeading': !showOtherDetails,
                  }"
                  @click="showDetails('other')"
                >
                  Other Details
                </p>
                <p
                  :class="{
                    'active-SubHeading': showAddressDetails,
                    'inactive-SubHeading': !showAddressDetails,
                  }"
                  @click="showDetails('address')"
                >
                  Address
                </p>
                <p
                  :class="{
                    'active-SubHeading': showComments,
                    'inactive-SubHeading': !showComments,
                  }"
                  @click="showDetails('comments')"
                >
                  Comments
                </p>
              </div>

              <div class="subSectionOfLeads col-md-12">
                <div v-show="showOtherDetails" class="sectionOther col-md-12">
                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="gender">Gender :</label>
                    <div class="col-md-6">
                      <select
                        class="form-control inputBg"
                        v-model="leadData.gender"
                        id="gender"
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="secondaryContactName"
                      >Secondary Contact Name :</label
                    >
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control w-100 inputBg"
                        v-model="leadData.secondaryContactName"
                        id="secondaryContactName"
                      />
                    </div>
                  </div>

                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="secondaryContactNumber"
                      >Secondary Contact Number:</label
                    >
                    <div class="col-md-6">
                      <input
                        type="tel"
                        class="form-control w-100 inputBg"
                        v-model="leadData.secondaryContactNumber"
                        id="secondaryContactNumber"
                        pattern="[0-9]{10}"
                      />
                      <small
                        v-if="
                          leadData.secondaryContactNumber &&
                          !isValidMobileNumber(leadData.secondaryContactNumber)
                        "
                        class="text-danger"
                        >Please enter a valid 10-digit mobile number.</small
                      >
                    </div>
                  </div>

                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="secondaryContactEmail"
                      >Secondary Contact Email Id:</label
                    >
                    <div class="col-md-6">
                      <input
                        type="email"
                        class="form-control w-100 inputBg"
                        v-model="leadData.secondaryContactEmail"
                        id="secondaryContactEmail"
                      />
                      <small
                        v-if="
                          leadData.secondaryContactEmail &&
                          !isValidEmail(leadData.secondaryContactEmail)
                        "
                        class="text-danger"
                        >Please enter a valid email address.</small
                      >
                    </div>
                  </div>

                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="aadhaarNo"
                      >Aadhar Number :</label
                    >
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control inputBg"
                        v-model="leadData.aadhaarNo"
                        id="aadhaarNo"
                        pattern="[0-9]{12}"
                      />
                      <small
                        v-if="
                          leadData.aadhaarNo && !isValidAdharNumber(leadData.aadhaarNo)
                        "
                        class="text-danger"
                        >Please enter a valid 12-digit Aadhar number.</small
                      >
                    </div>
                  </div>

                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="occupation"
                      >Occupation :</label
                    >
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control inputBg"
                        v-model="leadData.occupation"
                        id="occupation"
                      />
                    </div>
                  </div>

                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="propertyType"
                      >Property Type :</label
                    >
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control inputBg"
                        v-model="leadData.propertyType"
                        id="propertyType"
                      />
                    </div>
                  </div>

                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="GSTNumber"
                      >GST No :</label
                    >
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control inputBg"
                        v-model="leadData.GSTNumber"
                        id="GSTNumber"
                      />
                    </div>
                  </div>

                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="floorPlan"
                      >Upload Floor Plan:</label
                    >
                    <div class="col-md-6">
                      <input
                        type="file"
                        class="form-control inputBg"
                        @change="handleFileUpload(this.leadData.id)"
                        id="floorPlan"
                      />
                      <a
                        @click="
                          $viewFile(
                            leadData['awsFiles.awsFileName'],
                            leadData['awsFiles.fileName'],
                            'floorPlan',
                            'sales'
                          )
                        "
                        target="_blank"
                        class="mt-1"
                        >{{ this.leadData["awsFiles.fileName"] }}</a
                      >
                    </div>
                  </div>
                </div>

                <div v-show="showAddressDetails" class="sectionAddres row col-md-12">
                  <section class="col-md-6">
                    <h5 class="text-primary e-underline d-flex text-nowrap">
                      Billing Address
                      <span
                        style="font-size: 13px; margin-left: 10px; visibility: hidden"
                      >
                        <input
                          type="checkbox"
                          id="copyAddress"
                          class="form-check-input m-1"
                          v-model="copyAddress"
                          @change="copyBillingToShipping"
                        />
                        <label
                          class="form-check-label text-wrap text-black"
                          for="copyAddress"
                          >Copy Address same as Billing Address</label
                        >
                      </span>
                    </h5>
                    <div class="billingForm">
                      <span>
                        <label for="billingName">Name: </label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="billingAddressData.name"
                          id="billingName"
                        />
                      </span>
                      <span>
                        <label for="billingAddress">Address : </label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="billingAddressData.address"
                          id="billingAddress"
                        />
                      </span>
                      <span>
                        <label for="billingState">State :</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="billingAddressData.state"
                          id="billingState"
                        />
                      </span>
                      <span>
                        <label for="billingCity">City :</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="billingAddressData.city"
                          id="billingCity"
                        />
                      </span>
                      <span>
                        <label for="billingPincode">Pincode: </label>
                        <small
                          v-if="
                            billingAddressData.pincode &&
                            !isValidPinCode(billingAddressData.pincode)
                          "
                          class="text-danger ms-2"
                          >Please enter a valid 6-digit PinCode.</small
                        >
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="billingAddressData.pincode"
                          id="billingPincode"
                          pattern="[0-9]{6}"
                        />
                      </span>
                      <span>
                        <label for="billingPhoneNo">Phone No :</label>
                        <small
                          v-if="
                            billingAddressData.contactNo &&
                            !isValidMobileNumber(billingAddressData.contactNo)
                          "
                          class="text-danger ms-2"
                          >Please enter a valid 10-digit mobile number.</small
                        >
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="billingAddressData.contactNo"
                          id="billingPhoneNo"
                          pattern="[0-9]{10}"
                        />
                      </span>
                      <span>
                        <label for="billingEmailId">Email ID :</label>
                        <small
                          v-if="
                            billingAddressData.email &&
                            !isValidEmail(billingAddressData.email)
                          "
                          class="text-danger ms-2"
                          >Please enter a valid email address.</small
                        >
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="billingAddressData.email"
                          id="billingEmailId"
                        />
                      </span>
                    </div>
                  </section>

                  <section class="col-md-6">
                    <h5
                      class="text-primary e-underline d-flex align-content-center text-nowrap"
                    >
                      Shipping Address
                      <span style="font-size: 13px; margin-left: 10px">
                        <input
                          type="checkbox"
                          id="copyAddress"
                          class="form-check-input m-1"
                          v-model="copyAddress"
                          @change="copyBillingToShipping"
                        />
                        <label
                          class="form-check-label text-wrap text-black"
                          for="copyAddress"
                          >Copy Address same as Billing Address</label
                        >
                      </span>
                    </h5>
                    <div class="shippingForm">
                      <span>
                        <label for="shippingName">Name: </label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="addressData.name"
                          id="shippingName"
                        />
                      </span>
                      <span>
                        <label for="shippingAddress">Address : </label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="addressData.address"
                          id="shippingAddress"
                        />
                      </span>
                      <span>
                        <label for="shippingState">State :</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="addressData.state"
                          id="shippingState"
                        />
                      </span>
                      <span>
                        <label for="shippingCity">City :</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="addressData.city"
                          id="shippingCity"
                        />
                      </span>
                      <span>
                        <label for="shippingPincode">Pincode: </label>
                        <small
                          v-if="
                            addressData.pincode && !isValidPinCode(addressData.pincode)
                          "
                          class="text-danger ms-2"
                          >Please enter a valid 6-digit PinCode.</small
                        >
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="addressData.pincode"
                          id="shippingPincode"
                          pattern="[0-9]{6}"
                        />
                      </span>
                      <span>
                        <label for="shippingPhoneNo">Phone No :</label>
                        <small
                          v-if="
                            addressData.contactNo &&
                            !isValidMobileNumber(addressData.contactNo)
                          "
                          class="text-danger ms-1"
                          >Please enter a valid 10-digit mobile number.</small
                        >
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="addressData.contactNo"
                          id="shippingPhoneNo"
                          pattern="[0-9]{10}"
                        />
                      </span>
                      <span>
                        <label for="shippingEmailId">Email ID :</label>
                        <small
                          v-if="addressData.email && !isValidEmail(addressData.email)"
                          class="text-danger ms-2"
                          >Please enter a valid email address.</small
                        >
                        <input
                          type="text"
                          class="form-control inputBg"
                          v-model="addressData.email"
                          id="shippingEmailId"
                        />
                      </span>
                    </div>
                  </section>
                </div>

                <div v-show="showComments" class="sectionComments">
                  <section>
                    <label for="leadComments">Comments</label>
                    <textarea
                      name="leadComments"
                      id="leadComments"
                      class="form-control inputBg"
                      cols="30"
                      rows="8"
                      placeholder="Add Text....."
                      v-model="leadData.comment"
                    ></textarea>
                  </section>
                </div>
              </div>
            </section>

            <footer class="mt-4 d-flex justify-content-center align-conteSnt-center">
              <button
                v-if="selectedLeadId"
                class="btn btn-primary"
                @click="submitLeadData"
                :disabled="!isFormValid()"
                data-bs-dismiss="modal"
              >
                Update
              </button>
              <button
                v-else
                class="btn btn-primary"
                @click="submitLeadData"
                :disabled="!isFormValid()"
              >
                Save
              </button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "CreateLeadModal",
  props: {
    defaultSourceId: {
      type: String, // Change the type accordingly
      default: "", // Default value if not provided
    },
    selectedLeadId: {
      type: String, // Change the type according to your ID data type
      required: true,
    },
  },
  components: {},
  data() {
    return {
      floorPlan: null,
      SalesPersons: [],
      selectedSalesPerson: "",
      properties: [],
      selectedProperty: "",
      showDropdown1: false,
      showDropdown: false,
      showOtherDetails: true,
      showAddressDetails: false,
      showComments: false,
      source: ["Direct", "Agent"],
      leadData: {
        id: null,
        salutation: "",
        awsFileId: null,
        name: "",
        email: "",
        contactNo: "",
        priority: "",
        propertyName: "",
        gender: "",
        dob: null,
        secondaryContactName: "",
        secondaryContactNumber: "",
        secondaryContactEmail: "",
        aadhaarNo: "",
        occupation: "",
        propertyType: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        salesPersonId: null,
        propertyId: null,
        source: "",
        GSTNumber: "",
        comment: "",
        sourceId: "",
      },
      billingAddressData: {
        name: "", // Your initial companyName value
        email: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
        contactNo: "",
        addressType: "Billing", // Or use a variable if it varies
      },
      addressData: {
        name: "", // Your initial companyName value
        email: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
        contactNo: "",
        addressType: "Shipping",
      },
      PropertyData: {
        address: "",
        city: "",
        state: "",
        addressType: "Property",
      },
      ResidentialData: {
        address: "",
        city: "",
        state: "",
        addressType: "Residential",
      },
      copyAddress: false,
      SelectedLeadId: this.selectedLeadId,
    };
  },

  mounted() {
    this.leadData.sourceId = this.defaultSourceId;
    if (this.selectedLeadId !== null) {
      this.fetchLeadDetails();
    }
  },
  watch: {
    selectedLeadId(newValue) {
      if (newValue !== null) {
        this.SelectedLeadId = newValue;
        this.fetchLeadDetails();
      } else {
        this.leadData.id = newValue;
        this.leadData = {};
        this.PropertyData = {};
        this.ResidentialData = {};
        this.billingAddressData = {};
        this.addressData = {};
      }
    },
  },
  methods: {
    async submitLeadData() {
      try {
        if (this.selectedLeadId) {
          // If selectedLeadId is present, update the lead
          //  this.handleFileUpload(this.selectedLeadId);
          await this.updateLead();

          this.updateLeadAddresses();
        } else {
          // If selectedLeadId is not present, create a new lead
          await this.createLead();
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    getDownloadFilename(url) {
      return url;
    },
    handleFileUpload(SelectedLeadId) {
      console.log(SelectedLeadId);
      const file = document.getElementById("floorPlan").files[0]; // Get the selected file
      if (file) {
        // Make API call to get signed URL for file upload
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: "floorPlan",
            moduleName: "sales",
            entityId: SelectedLeadId,
            entityName: "lead",
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;
            this.leadData.awsFileId = response.data.id;

            // Use XMLHttpRequest to upload the file to the signed URL
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  console.log("Profile photo uploaded successfully:", xhr.responseText);
                  // this.featchLeadfloorPlan(LeadId);
                  // Handle successful upload
                } else {
                  console.error("Error uploading profile photo to S3:", xhr.responseText);
                  // Handle upload error
                }
              }
            };

            // Send the file as binary data
            xhr.send(file);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    isFormValid() {
      // Check if mobile number is valid
      const isMobileValid = this.leadData.contactNo;
      // ? this.isValidMobileNumber(this.leadData.contactNo)
      // : false;
      // Check if email is valid
      // const isEmailValid = this.leadData.email
      //   ? this.isValidEmail(this.leadData.email)
      //   : false;

      // Return true only if both mobile number and email are valid
      return isMobileValid;
    },
    isValidPinCode(number) {
      const pinCodeRegex = /^\d{6}$/;
      return pinCodeRegex.test(number);
    },

    // Validate email
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    isValidMobileNumber(number) {
      const mobileRegex = /^\d{10}$/;
      return mobileRegex.test(number);
    },
    isValidAdharNumber(number) {
      const mobileRegex = /^\d{12}$/;
      return mobileRegex.test(number);
    },

    async updateLeadAddresses() {
      try {
        const addressesResponse = await axios.get(
          `${this.$apiEndPoint}/leadsAddress/${this.selectedLeadId}`
        );
        const addresses = addressesResponse.data;

        const billingAddress = addresses.find((addr) => addr.addressType === "Billing");
        const shippingAddress = addresses.find((addr) => addr.addressType === "Shipping");
        const residentialAddress = addresses.find(
          (addr) => addr.addressType === "Residential"
        );
        const propertyAddress = addresses.find((addr) => addr.addressType === "Property");

        const promises = [];

        // Update the existing addresses if found
        if (billingAddress) {
          promises.push(
            axios.put(
              `${this.$apiEndPoint}/leadsAddress/${this.selectedLeadId}/Billing`,
              this.billingAddressData
            )
          );
        } else {
          // If billing address doesn't exist, create it
          promises.push(this.createLeadAddress("Billing", this.selectedLeadId));
        }

        if (shippingAddress) {
          promises.push(
            axios.put(
              `${this.$apiEndPoint}/leadsAddress/${this.selectedLeadId}/Shipping`,
              this.addressData
            )
          );
        } else {
          // If shipping address doesn't exist, create it
          promises.push(this.createLeadAddress("Shipping", this.selectedLeadId));
        }

        if (residentialAddress) {
          promises.push(
            axios.put(
              `${this.$apiEndPoint}/leadsAddress/${this.selectedLeadId}/Residential`,
              this.ResidentialData
            )
          );
        } else {
          // If residential address doesn't exist, create it
          promises.push(this.createLeadAddress("Residential", this.selectedLeadId));
        }

        if (propertyAddress) {
          promises.push(
            axios.put(
              `${this.$apiEndPoint}/leadsAddress/${this.selectedLeadId}/Property`,
              this.PropertyData
            )
          );
        } else {
          // If property address doesn't exist, create it
          promises.push(this.createLeadAddress("Property", this.selectedLeadId));
        }

        // Execute all promises concurrently
        await Promise.all(promises);
        this.$emit("fetchData");

        console.log("All addresses updated successfully.");
      } catch (error) {
        this.$handleError(error);
        this.$emit("fetchData");
      }
    },

    async createLeadAddress(addressType, leadId) {
      try {
        let addressData = null;

        // Set the appropriate address data based on the address type
        switch (addressType) {
          case "Billing":
            addressData = this.billingAddressData;
            break;
          case "Shipping":
            addressData = this.addressData;
            break;
          case "Residential":
            addressData = this.ResidentialData;
            break;
          case "Property":
            addressData = this.PropertyData;
            break;
          default:
            throw new Error(`Invalid address type: ${addressType}`);
        }

        // Set the leadId in the address data object
        addressData.leadId = leadId;

        // Post the address data to create the address
        await axios.post(`${this.$apiEndPoint}/leadsAddress/`, addressData);
        console.log(`${addressType} address created successfully.`);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchLeadDetails() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/leads/getByLeadId/${this.SelectedLeadId}`
        );
        this.leadData = response.data;

        this.fetchLeadAddresses(this.SelectedLeadId);
      } catch (error) {
        this.$handleError(error);
      }
    },
    fetchLeadAddresses(leadId) {
      axios
        .get(`${this.$apiEndPoint}/leadsAddress/${leadId}`)
        .then((response) => {
          const addresses = response.data;

          const ResidentialAddress = addresses.find(
            (addr) => addr.addressType === "Residential"
          );
          const PropertyAddress = addresses.find(
            (addr) => addr.addressType === "Property"
          );
          const billingAddress = addresses.find((addr) => addr.addressType === "Billing");
          const shippingAddress = addresses.find(
            (addr) => addr.addressType === "Shipping"
          );

          if (billingAddress) {
            this.billingAddressData = {
              name: billingAddress.name,
              address: billingAddress.address,
              city: billingAddress.city,
              state: billingAddress.state,
              country: billingAddress.country,
              pincode: billingAddress.pincode,
              contactNo: billingAddress.contactNo,
              email: billingAddress.email,
            };
          }

          if (ResidentialAddress) {
            this.ResidentialData = {
              address: ResidentialAddress.address,
              city: ResidentialAddress.city,
              state: ResidentialAddress.state,
            };
          }
          if (PropertyAddress) {
            this.PropertyData = {
              address: PropertyAddress.address,
              city: PropertyAddress.city,
              state: PropertyAddress.state,
            };
          }
          if (shippingAddress) {
            this.addressData = {
              name: shippingAddress.name,
              address: shippingAddress.address,
              city: shippingAddress.city,
              state: shippingAddress.state,
              country: shippingAddress.country,
              pincode: shippingAddress.pincode,
              contactNo: shippingAddress.contactNo,
              email: shippingAddress.email,
            };
          }
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    async fetchSalesPersons() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee`);
        this.SalesPersons = response.data;

        console.log(this.properties);
      } catch (error) {
        this.$handleError(error);
      }
    },
    searchSalesPerson() {
      this.showDropdown1 = this.selectedSalesPerson.trim() !== "";
    },
    selectSalesPerson(salesPerson) {
      this.selectedSalesPerson = salesPerson["users.firstname"];
      this.leadData.salesPersonId = salesPerson.id;
      this.showDropdown1 = false;
    },

    async fetchProperties() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/property/getAllProperties`
        );
        this.properties = response.data;
        console.log(this.properties);
      } catch (error) {
        this.$handleError(error);
      }
    },
    searchProperties() {
      this.showDropdown = this.selectedProperty.trim() !== "";
    },
    selectProperty(property) {
      this.selectedProperty = property.name;
      this.leadData.propertyId = property.id;
      this.showDropdown = false;
    },

    addCustomSource() {
      const newSource = prompt("Enter new source:");
      if (newSource) {
        this.source.push(newSource);
      }
    },
    showDetails(section) {
      this.showOtherDetails = false;
      this.showAddressDetails = false;
      this.showComments = false;

      // Set the specific section to true based on the button clicked
      if (section === "other") {
        this.showOtherDetails = true;
      } else if (section === "address") {
        this.showAddressDetails = true;
      } else if (section === "comments") {
        this.showComments = true;
      }
    },

    async updateLead() {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/leads/${this.selectedLeadId}`,
          this.leadData
        );

        console.log("Lead Updated:", response.data);
        this.$emit("fetchData");
      } catch (error) {
        this.$emit("fetchData");
        this.$handleError(error);
      }
    },
    async createLead() {
      try {
        // Create the lead and get the lead ID
        const leadResponse = await axios.post(
          `${this.$apiEndPoint}/leads/`,
          this.leadData
        );
        const leadId = leadResponse.data.id;

        // Set the leadId for the related data
        this.billingAddressData.leadId = leadId;
        this.PropertyData.leadId = leadId;
        this.ResidentialData.leadId = leadId;
        this.addressData.leadId = leadId;

        // Send address requests and await their completion
        await this.sendAddressRequests();

        // Reset the lead data and fetch updated data
        this.resetLeadData();
        this.$emit("fetchData");
      } catch (error) {
        this.$handleError(error);
        this.$emit("fetchData");
        this.resetLeadData();
      }
    },

    async sendAddressRequests() {
      // Prepare the promises for concurrent execution
      const addressRequests = [
        axios.post(`${this.$apiEndPoint}/leadsAddress/`, this.PropertyData),
        axios.post(`${this.$apiEndPoint}/leadsAddress/`, this.ResidentialData),
        axios.post(`${this.$apiEndPoint}/leadsAddress/`, this.billingAddressData),
        axios.post(`${this.$apiEndPoint}/leadsAddress/`, this.addressData),
      ];

      // Execute all address requests concurrently and wait for them to finish
      await Promise.all(addressRequests);
      this.$toast(`Lead created successfully..`, "success");

      // Reload the page after all requests have completed
      window.location.reload();
    },

    async sendLeadComment(leadId) {
      const payload = {
        leadId: leadId, // Use the provided leadId parameter
        comment: this.leadData.comment,
      };

      try {
        // Make an API request to update the comments
        const response = await axios.post(`${this.$apiEndPoint}/leadsComments`, payload);
        this.leadData.comment = ""; // Clear the comment field after successful submission

        console.log("Comments updated successfully:", response.data);
      } catch (error) {
        this.$handleError(error);
      }
    },

    resetLeadData() {
      this.leadData = {
        id: null,
        salutation: "",
        name: "",
        gender: "",
        dob: null,
        aadhaarNo: "",
        occupation: "",
        email: "",
        contactNo: "",
        propertyType: "",
        address: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        salesPersonId: null,
        propertyId: null,
        source: "",
      };

      this.billingAddressData = {
        companyId: "",
        name: "",
        email: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
        contactNo: "",
        addressType: "",
      };

      this.addressData = {
        companyId: "",
        name: "",
        email: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
        contactNo: "",
        addressType: "",
      };

      this.PropertyData = {
        address: "",
        city: "",
        state: "",
        addressType: "Property",
      };

      this.ResidentialData = {
        address: "",
        city: "",
        state: "",
        addressType: "Residential",
      };
    },

    copyBillingToShipping() {
      if (this.copyAddress) {
        // Copy billing address to shipping address
        this.addressData.name = this.billingAddressData.name;
        this.addressData.address = this.billingAddressData.address;
        this.addressData.state = this.billingAddressData.state;
        this.addressData.city = this.billingAddressData.city;
        this.addressData.pincode = this.billingAddressData.pincode;
        this.addressData.contactNo = this.billingAddressData.contactNo;
        this.addressData.email = this.billingAddressData.email;
      } else {
        // Reset shipping address
        this.addressData.name = "";
        this.addressData.address = "";
        this.addressData.state = "";
        this.addressData.city = "";
        this.addressData.pincode = "";
        this.addressData.contactNo = "";
        this.addressData.email = "";
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.cdnfonts.com/css/inter");

.modal-content {
  height: 100%; /* Ensure modal content fills the height */
}

input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}

label {
  font-weight: 500;
  color: black;
  font-size: 12px;
}
input,
textarea,
select {
  border: 1px solid #ccc;
  outline: none !important;
  font-size: 14px;
}

/*
.modal-dialog.modal-lg {
  max-width: 100%; Full width on large screens 
}*/
.createLeadForm {
  min-width: 100%;
  margin-left: 24%;
  font-size: 16px;
  color: #000000;
  height: 100%;
  overflow-y: scroll;
}
.subHeadingsOfLeads p {
  cursor: pointer;
}

.subHeadingsOfLeads p:nth-child(2) {
  margin: 0 10%;
}
.subHeadingsOfLead {
  display: flex;
  align-content: center;
}
.subHeadingsOfLead p {
  margin-bottom: 0 !important;
}
.text-danger {
  font-size: 10px;
}
@media (max-width: 767px) {
  .createLeadForm {
    max-width: 100%;
    /* Full width on mobile */
    margin-left: 0;
    /* Reset margin for mobile */
  }
  .subHeadingsOfLeads p:nth-child(2) {
    margin: 0 3%;
  }
}
</style>
.modal { left: 100%; /* Initial position off-screen to the left */ top: 0; width: 100%;
min-height: 100%; z-index: 10500; /* Ensure it overlays other content */ transition: left
0.3s ease; /* Transition effect for left position */ } .modal.show { left: 0; /* Position
the modal to the left edge of the screen */ } .modal-dialog { position: relative; width:
100%; /* Adjust the width as needed */ } .createLeadForm { width: 100%; font-size: 14px;
font-family: "Inter", sans-serif; color: #000000; font-weight: lighter; height: 100%; /*
Set height to 100% */ overflow-y: auto; /* Enable vertical scrolling if content exceeds
viewport height */ } .modal-body { padding: 0; /* Remove default padding to avoid
unnecessary space */ } .modal-dialog.modal-lg { max-width: 100%; /* Full width on large
screens */ } .subHeadingsOfLeads h5:nth-child(2) { margin: 0 10%; } @media (max-width:
767px) { .createLeadForm { max-width: 100%; /* Full width on mobile */ margin-left: 0; /*
Reset margin for mobile */ } .subHeadingsOfLeads h5:nth-child(2) { margin: 0 3%; } }
