<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="targetSetterModal"
    tabindex="-1"
    aria-labelledby="targetSetterModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="targetSetterModalLabel">Set Target</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row mb-1">
            <label for="setValue" class="col-sm-4 col-form-label">Set Value:</label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                id="setValue"
                v-model="Target.targetAmount"
                @input="validateSetValue"
              />
              <div v-if="!isValidSetValue" class="text-danger">
                Please enter a valid number.
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <label for="startMonth" class="col-sm-4 col-form-label">From:</label>
            <div class="col-sm-4">
              <select class="form-control" id="startMonth" v-model="Target.fromMonth">
                <option v-for="(month, index) in months" :value="index + 1" :key="index">
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="col-sm-4">
              <select class="form-control" id="startYear" v-model="Target.fromYear">
                <option v-for="year in fromYears" :value="year" :key="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-1">
            <label for="endMonth" class="col-sm-4 col-form-label">To:</label>
            <div class="col-sm-4">
              <select class="form-control" id="endMonth" v-model="Target.toMonth">
                <option v-for="(month, index) in months" :value="index + 1" :key="index">
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="col-sm-4">
              <select class="form-control" id="endYear" v-model="Target.toYear">
                <option v-for="year in toYears" :value="year" :key="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <button
              v-if="!this.Target.id"
              class="btn btn-success"
              @click="handleSaveOrUpdate"
              :disabled="!isValidSetValue"
              data-bs-dismiss="modal"
            >
              Save
            </button>
            <button
              v-else
              class="btn btn-success"
              @click="handleSaveOrUpdate"
              :disabled="!isValidSetValue"
              data-bs-dismiss="modal"
            >
              Update
            </button>
            <button class="btn btn-secondary ms-2" data-bs-dismiss="modal">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "SalesTargetModal",
  props: {
    selectedEmployeeId: {
      type: String,
      required: true,
    },
    selectedTargetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      Target: {
        id: null,
        targetAmount: "",
        fromMonth: "",
        fromYear: "",
        toMonth: "",
        toYear: "",
        employeeId: this.empId,
      },
      filterArray: [],
      setTargetedYearandMonth: [], // This will store the existing targets
      empId: "",
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      currentYear: new Date().getFullYear(),
      isValidSetValue: true,
    };
  },
  computed: {
    fromYears() {
      return Array.from({ length: 12 }, (_, i) => this.currentYear - 1 + i);
    },
    toYears() {
      return Array.from({ length: 11 }, (_, i) => {
        if (this.Target.fromYear) {
          return parseInt(this.Target.fromYear) + i;
        } else {
          return this.currentYear + i;
        }
      });
    },
  },
  mounted() {
    this.empId = this.selectedEmployeeId;
    this.Target.id = this.selectedTargetId;
    if (this.selectedTargetId) {
      this.featchTargetIdvalue();
    }
    this.AlreadyTargetSetData(); // Fetch existing target data when the component is mounted
  },
  watch: {
    selectedTargetId(newValue) {
      if (newValue !== null) {
        this.Target.id = newValue;
        this.featchTargetIdvalue();
        this.AlreadyTargetSetData(); // Re-fetch existing target data when targetId changes
      } else {
        this.Target.id = newValue;
        this.Target = {};
        this.AlreadyTargetSetData();
      }
    },
    selectedEmployeeId(newValue) {
      if (newValue !== null) {
        // Logic for when newValue is not null
        console.log("Employee ID selected:", newValue);
        this.AlreadyTargetSetData(); // Re-fetch existing target data
      } else {
        // Logic for when newValue is null
        console.log("No Employee ID selected.");
        // Optionally handle the case when no employee is selected
      }
    },
  },
  methods: {
    async AlreadyTargetSetData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/salesTarget/monthAndYear/${this.selectedEmployeeId}`
        );
        this.setTargetedYearandMonth = response.data;
        console.log(this.setTargetedYearandMonth);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async featchTargetIdvalue() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/salesTarget/getById/${this.selectedTargetId}`
        );

        // Assign the fetched target data
        this.Target = response.data;
        console.log(this.Target);

        // Destructure the fromMonth, fromYear, toMonth, and toYear from the fetched target
        const { fromMonth, fromYear, toMonth, toYear } = this.Target;

        // Filter the setTargetedYearandMonth array to remove the target's from and to month/year
        this.filterArray = this.setTargetedYearandMonth.filter((item) => {
          return !(
            (item.month === fromMonth && item.year === fromYear) ||
            (item.month === toMonth && item.year === toYear)
          );
        });

        console.log("Filtered Array:", this.filterArray);
      } catch (error) {
        this.$handleError(error);
      }
    },

    validateExistingTarget() {
      const fromMonth = Number(this.Target.fromMonth);
      const toMonth = Number(this.Target.toMonth);
      const fromYear = Number(this.Target.fromYear);
      const toYear = Number(this.Target.toYear);

      return this.setTargetedYearandMonth.find(
        (target) =>
          // Exclude the current target from the validation if updating
          // this.Target.id !== target.id &&
          (Number(target.month) === fromMonth && Number(target.year) === fromYear) ||
          (Number(target.month) === toMonth && Number(target.year) === toYear)
      );
    },
    async handleSaveOrUpdate() {
      if (this.validateSetValue() && this.validateTargetPeriod()) {
        let existingTarget = null;
        // If updating, validate the month and year but exclude the current target
        if (this.Target.id) {
          existingTarget = this.filterArray.find(
            (target) =>
              (Number(target.month) === Number(this.Target.fromMonth) &&
                Number(target.year) === Number(this.Target.fromYear)) ||
              (Number(target.month) === Number(this.Target.toMonth) &&
                Number(target.year) === Number(this.Target.toYear))
          );
        } else {
          // For new targets, perform regular validation
          existingTarget = this.validateExistingTarget();
        }

        // If a conflicting target exists, show an alert and stop the process
        if (existingTarget) {
          alert(
            `Target for ${this.months[existingTarget.month - 1]} ${
              existingTarget.year
            } has already been set. Please choose a different month and year.`
          );
          return;
        }

        // Proceed with update if a Target ID is present
        if (this.Target.id) {
          await this.TargetUpdateToEmployee();
        } else {
          // Otherwise, create a new target
          await this.TargetSetToEmployee();
        }
      } else if (!this.validateTargetPeriod()) {
        alert("The 'To' period should not be less than the 'From' period.");
      }
    },

    async TargetUpdateToEmployee() {
      try {
        await axios.put(
          `${this.$apiEndPoint}/salesTarget/${this.selectedTargetId}`,
          this.Target
        );
        this.$toast(` Target updated successfully.`, "success");
        this.$emit("fetchData");
      } catch (error) {
        this.$handleError(error);
      }
    },
    async TargetSetToEmployee() {
      this.Target.employeeId = this.selectedEmployeeId;
      try {
        await axios.post(`${this.$apiEndPoint}/salesTarget`, this.Target);
        this.$toast(` Target set successfully.`, "success");
        this.$emit("fetchData");
        this.Target = {};
      } catch (error) {
        this.$handleError(error);
      }
    },
    validateSetValue() {
      const value = parseFloat(this.Target.targetAmount);
      this.isValidSetValue = !isNaN(value) && value >= 0;
      return this.isValidSetValue;
    },
    validateTargetPeriod() {
      const fromDate = new Date(this.Target.fromYear, this.Target.fromMonth - 1);
      const toDate = new Date(this.Target.toYear, this.Target.toMonth - 1);
      return toDate >= fromDate;
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
</style>
