<template>
  <div>
    <!-- Bootstrap modal -->
    <div
      class="modal fade"
      id="addAddressesModal"
      aria-labelledby="addAddressesModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <!-- Modal header -->
          <div class="modal-header">
            <h5 class="modal-title">New Address</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <!-- Modal content -->
            <div class="mb-2 row">
              <label for="addressType" class="col-sm-3 col-form-label text-black"
                >Location Type</label
              >
              <div class="col-sm-9">
                <select
                  class="form-select"
                  id="addressType"
                  v-model="formData.addressType"
                >
                  <option value="Warehouse">Warehouse</option>
                  <option value="BranchOffice">Office</option>
                  <option value="Factory">Factory</option>
                </select>
              </div>
            </div>
            <div class="mb-2 row">
              <label for="name" class="col-sm-3 col-form-label text-black"
                >Location Name</label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="formData.name"
                />
              </div>
            </div>
            <div class="mb-2 row">
              <label for="address" class="col-sm-3 col-form-label text-black"
                >Address</label
              >
              <div class="col-sm-9">
                <textarea
                  class="form-control"
                  id="address"
                  rows="3"
                  v-model="formData.address"
                ></textarea>
              </div>
            </div>
            <div class="mb-2 row">
              <label for="city" class="col-sm-3 col-form-label text-black">City</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  v-model="formData.city"
                />
              </div>
            </div>
            <div class="mb-2 row">
              <label for="state" class="col-sm-3 col-form-label text-black">State</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  id="state"
                  v-model="formData.state"
                />
              </div>
            </div>
            <div class="mb-2 row">
              <label for="pincode" class="col-sm-3 col-form-label text-black"
                >Zip Code</label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  id="pincode"
                  v-model="formData.pincode"
                />
              </div>
            </div>
            <div class="mb-2 row">
              <label for="contactNo" class="col-sm-3 col-form-label text-black"
                >Contact No</label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  id="contactNo"
                  v-model="formData.contactNo"
                />
              </div>
            </div>
            <div class="mb-2 row">
              <label for="email" class="col-sm-3 col-form-label text-black"
                >Email ID</label
              >
              <div class="col-sm-9">
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="formData.email"
                />
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer d-flex justify-content-center">
            <button
              v-if="!this.formData.id"
              type="button"
              class="btn btn-primary"
              @click="postAddress"
              data-bs-dismiss="modal"
            >
              Done
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary"
              @click="postAddress"
              data-bs-dismiss="modal"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AddAddressesModal",
  props: {
    selectedAddressId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        id: null,
        addressType: "",
        name: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        contactNo: "",
        email: "",
        entityId: this.$LocalUser.user.companyId,
        entityName: "Company",
      },
    };
  },
  watch: {
    selectedAddressId(newValue) {
      if (newValue !== null) {
        this.formData.id = newValue;
        this.fetchAddressById();
      } else {
        this.formData.id = newValue;
        this.formData = {};
        this.resetForm();
      }
    },
  },
  methods: {
    async fetchAddressById() {
      console.log(this.formData.id);
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/procurementAddress/${this.formData.id}`
        );
        this.formData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async postAddress() {
      console.log(this.formData);
      try {
        if (this.formData.id) {
          // Update existing address
          const response = await axios.put(
            `${this.$apiEndPoint}/procurementAddress/${this.formData.id}`,
            this.formData
          );
          this.$emit("fetchAddressData");
          console.log("Address updated successfully:", response.data);
        } else {
          // Create new address
          const response = await axios.post(
            `${this.$apiEndPoint}/procurementAddress/`,
            this.formData
          );
          console.log("Address posted successfully:", response.data);
          this.resetForm();
          this.$emit("fetchAddressData");
        }

        // Reset the form after submission
      } catch (error) {
        this.$handleError(error);
      }
    },
    resetForm() {
      this.formData = {
        id: null,
        addressType: "",
        name: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        contactNo: "",
        email: "",
        entityId: this.$LocalUser.user.companyId,
        entityName: "Company",
      };
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
label {
  font-weight: 500;
  color: black;
  font-size: 14px;
}
input,
textarea,
select {
  border: 1px solid #000000;
  outline: none !important;
  font-size: 14px;
}
</style>
