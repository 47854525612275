<template>
  <div
    class="modal fade"
    id="OnSiteStockConsumptionModal"
    tabindex="-1"
    aria-labelledby="OnSiteStockConsumptionModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="min-width: 60%; overflow: auto"
    >
      <div class="modal-content">
        <div class="modal-header" style="margin-bottom: 0">
          <div class="d-flex justify-content-between align-items-center w-100">
            <h6 class="modal-title">Stock Consumption</h6>
            <div class="d-flex align-items-center">
              <label for="date" class="col-form-label text-nowrap">Date:</label>
              <input type="date" class="form-control p-1 ms-3" v-model="form.date" />
            </div>
            <img
              src="../../assets/icons/cancel.png"
              alt="Close"
              style="width: 20px; height: 20px; cursor: pointer"
              data-bs-dismiss="modal"
            />
          </div>
        </div>
        <div class="modal-body" style="max-height: 500px; overflow: auto">
          <table
            class="table files-table table-responsive"
            style="background-color: #ccc"
          >
            <caption class="d-none">
              Product details
            </caption>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Product Name</th>
                <th>Qty</th>
                <th>Unit Type</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in stocks"
                :key="index"
                @mouseover="deleteItems = true"
              >
                <td>{{ index + 1 }}</td>
                <td>
                  <input
                    class="w-100 form-control"
                    list="products"
                    v-model="row.productName"
                    @change="fillProductDetails(index)"
                    :ref="'productNameInput' + index"
                  />
                  <datalist id="products" style="background-color: whitesmoke">
                    <option
                      v-for="product in Products"
                      :key="product.id"
                      :value="product.productName"
                    ></option>
                  </datalist>
                </td>
                <td>
                  <input type="number" class="form-control" v-model="row.quantity" />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="row.unitType"
                    readOnly
                  />
                </td>
                <td>
                  <img
                    v-if="deleteItems"
                    style="width: 13px; height: 13px; cursor: not-allowed"
                    src="../../assets/icons/redDelete.png"
                    data-toggle="tooltip"
                    data-placement="top-start"
                    title="Delete"
                    alt="Delete"
                    @click="removeRow(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button @click="addRow" class="btn btn-sm btn-primary">Add Row</button>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            v-if="this.Update"
            type="button"
            class="browse"
            data-bs-dismiss="modal"
            @click="updateStock"
          >
            Update
          </button>
          <button
            v-else
            type="button"
            class="browse"
            @click="CreateStockConsumption"
            data-bs-dismiss="modal"
          >
            Save
          </button>

          <button type="button" class="cancelButton" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OnSiteStockConsumptionModal",
  props: {
    CompanyprojectId: {
      type: Number,
      required: true,
    },
    StockNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      Update: true,
      localStockEntryId: null,
      deleteItems: false,
      Products: [],
      form: {
        date: new Date().toISOString().slice(0, 10), // Initialize form date with current date
        stocks: [],
      },

      stocks: [
        {
          id: null,
          stockNumber: this.StockNumber,
          productName: "",
          quantity: 0,
          unitType: "",
          productId: "",
          projectId: this.CompanyprojectId, // Initialize projectId
          date: new Date().toISOString().slice(0, 10),
        },
      ],
    };
  },
  mounted() {
    this.fetchProductList();
    console.log(this.CompanyprojectId);
  },
  watch: {
    CompanyprojectId(newValue) {
      console.log(newValue);
      console.log(this.CompanyprojectId);
    },

    StockNumber(newValue) {
      this.localStockEntryId = newValue;
      console.log(this.localStockEntryId);
      if (newValue !== "null") {
        this.Update = true;
        // Check for truthy value, which handles both null and undefined
        this.featchEntriesById(this.localStockEntryId); // Corrected the typo: "featchEntriesById" to "fetchEntriesById"
        console.log(this.localStockEntryId);
        console.log(this.CompanyprojectId);
      } else {
        this.Update = false;
        this.stocks = [];
        this.stocks.push({
          id: null,
          stockNumber: this.StockNumber,
          productName: "",
          quantity: 0,
          unitType: "",
          projectId: this.CompanyprojectId, // Assign projectId to new row
          date: new Date().toISOString().slice(0, 10), // Set current date
        });
      }
    },
  },
  methods: {
    async featchEntriesById(InwardEntryId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/stockConsumption/${InwardEntryId}`
        );
        this.form = response.data;

        this.stocks = response.data.productList;
        console.log(response.data.productList);
      } catch (error) {
        this.$handleError(error);
      }
    },
    fetchProductList() {
      axios
        .get(`${this.$apiEndPoint}/productList`)
        .then((response) => {
          this.Products = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    fillProductDetails(index) {
      const product = this.Products.find(
        (p) => p.productName === this.stocks[index].productName
      );
      if (product) {
        this.stocks[index].unitType = product.unitType;
        this.stocks[index].productId = product.id;
      }
    },
    addRow() {
      if (this.stocks.length > 0) {
        const lastRow = this.stocks[this.stocks.length - 1];
        if (lastRow.productName.trim() !== "") {
          this.stocks.push({
            stockNumber: this.localStockEntryId,
            productName: "",
            quantity: 0,
            unitType: "",
            projectId: this.CompanyprojectId, // Assign projectId to new row
            date: new Date().toISOString().slice(0, 10), // Set current date
          });
        } else {
          this.$nextTick(() => {
            this.$refs[`productNameInput${this.stocks.length - 1}`][0].focus();
          });
        }
      } else {
        this.stocks.push({
          stockNumber: this.localStockEntryId,
          productName: "",
          quantity: 0,
          unitType: "",
          projectId: this.CompanyprojectId, // Assign projectId to new row
          date: new Date().toISOString().slice(0, 10), // Set current date
        });
      }
    },
    removeRow(index) {
      const stockId = this.stocks[index].id; // Save the id before splicing
      this.stocks.splice(index, 1);
      console.log(stockId);
      if (stockId) {
        axios
          .delete(`${this.$apiEndPoint}/stockConsumption/deleteLineitem/${stockId}`)
          .then((response) => {
            console.log("Entry deleted successfully:", response.data);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },

    updateStock() {
      // Log the current stocks to console for debugging purposes
      console.log(this.stocks);

      // Send the updated stock data to the server
      axios
        .put(`${this.$apiEndPoint}/stockConsumption`, { stocks: this.stocks })
        .then((response) => {
          // Emit an event to fetch the updated stock consumption entries
          this.$emit("fetchStockConsumptionEntries");
          console.log("Entry Updated successfully:", response.data);
          this.form = {
            date: new Date().toISOString().slice(0, 10), // Reset form date with current date
            stocks: [],
          };
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    CreateStockConsumption() {
      this.form.stocks = [...this.stocks];

      console.log(this.form);

      // Send this.form to the server
      axios
        .post(`${this.$apiEndPoint}/stockConsumption`, this.form)
        .then((response) => {
          this.$emit("fetchStockConsumptionEntries");
          console.log("Entry created successfully:", response.data);
          this.form = {
            date: new Date().toISOString().slice(0, 10), // Reset form date with current date
            stocks: [],
          };
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}
.selected-employee {
  margin-bottom: 5px;
}
.remove-employee {
  cursor: pointer;
  margin-left: 5px;
}
label {
  font-weight: 500;
  color: black;
}
input,
select {
  outline: none !important;
  padding: 2px !important;
}
select {
  height: fit-content;
}
.modal-body {
  max-height: 500px;
  overflow-y: auto;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table .sticky-header th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.files-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.files-table th,
.files-table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #ddd;
}

.files-table th {
  background-color: #bcbfc6;
}

.files-table tr:hover {
  background-color: #f5f5f5;
}

.files-table th:nth-child(2),
.files-table td:nth-child(2) {
  width: 60%;
  text-align: left;
}
</style>
