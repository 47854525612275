<template>
  <div></div>
</template>

<script>
// import Header from "./Header.vue";
// import Nav from "./Nav.vue";
export default {
  name: "Template",
  components: {
    // Header,
    // Nav,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped></style>
