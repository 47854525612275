<template>
  <div class="containerrr">
    <div class="loader">
      <div class="spinnerBlock">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToolCenterLoader",
};
</script>

<style scoped>
.containerrr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  position: relative;
  .spinnerBlock {
    animation: rotate 600ms linear 600ms infinite;
    transform-origin: center;
    display: block;
    height: 50px;
    width: 50px;
  }
  span {
    display: block;
    border: 2px solid #bcbfc6;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &:nth-child(1) {
      border-color: #eee;
    }
    &:nth-child(2) {
      left: -18px;
      top: 10px;
    }
    &:nth-child(3) {
      left: -18px;
      top: -10px;
    }
    &:nth-child(4) {
      left: 0;
      top: -18px;
    }
    &:nth-child(5) {
      left: 18px;
      top: -10px;
    }
    &:nth-child(6) {
      left: 18px;
      top: 10px;
    }
    &:nth-child(7) {
      left: 0;
      top: 18px;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
