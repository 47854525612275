<template>
  <div>
    <MailSentModal
      :estimateIndex="routeParams.estimateIndex"
      :leadEmail="routeParams.email"
    />
    <div class="FinalEstimate border-black">
      <div class="row subHeaderHeadingBgColor align-items-center FinalEstimateHeader m-2">
        <div class="col-8 mt-1 companyName">
          <h4>ARIVU</h4>
          <h4 class="estimate">Estimate</h4>
        </div>
      </div>

      <div class="EstimateContent mb-2">
        <div
          class="row align-items-center align-content-md-start container-fluid headerFormOfEstimate"
        >
          <div class="col-md-4 col-12">
            <div class="glass-effect mb-1">
              <img
                :src="CompanyLogo"
                alt="Company Logo"
                id="companylogoInput"
                class="img-fluid"
              />

              <div class="glass-overlay"></div>
            </div>

            <div class="col-md-12 d-flex EstimateLogoHeader">
              <div v-if="this.companyDetails">
                <span style="font-weight: 600">{{ companyDetails.companyName }}</span>
                <br />
                <span>
                  <span>
                    {{ companyDetails.address }} {{ companyDetails.city }}
                    {{ companyDetails.state }}
                  </span>
                </span>
                <br />

                <span>
                  {{ companyDetails.contactNumber }}
                </span>
                <br />
                <span>
                  {{ companyDetails.companyEmail }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-12 order-md-first">
            <section class="EstimateHeader">
              <div class="row">
                <label for="clientName" class="col-2">Client Name</label>
                <span class="col-1">:</span>
                <span class="form-control-static col-8">{{
                  routeParams.clientName
                }}</span>
              </div>
              <div class="row">
                <label for="contactNumber " class="col-2">Contact No</label>
                <span class="col-1">:</span>
                <span class="form-control-static col-9">{{ routeParams.contactNo }}</span>
              </div>

              <div class="row">
                <label for="emailId" class="col-2 mb-1">Email ID</label>
                <span class="col-1">:</span>
                <span class="form-control-static col-9">{{ routeParams.email }}</span>
              </div>
              <div class="row">
                <label for="propertyName" class="col-2 text-nowrap"
                  >Property Address</label
                >
                <span class="col-1">:</span>
                <span class="form-control-static col-9">{{ routeParams.address }}</span>
              </div>
              <div class="row">
                <label for="estimateNumber" class="col-2">Estimate No. </label>
                <span class="col-1">:</span>
                <span class="form-control-static col-9">
                  {{ routeParams.estimateId }}
                </span>
              </div>

              <div class="row">
                <label class="col-2" id="EstimateDate">Date </label>
                <span class="col-1">:</span>
                <span class="form-control-static col-9" id="EstimateDate">
                  {{ routeParams.date }}
                </span>
              </div>
            </section>
          </div>
        </div>
        <div class="EstimateBill table-responsive">
          <table class="tableOfEstimate table">
            <caption class="d-none">
              Table
            </caption>
            <thead class="">
              <tr class="p-1">
                <th>SL.No</th>
                <th>Description</th>
                <th>
                  Width

                  <div v-if="toggleWidththings">
                    <select
                      v-model="routeParams.widthUnitType"
                      v-if="!routeParams.widthUnitType"
                    >
                      <option
                        v-for="(unit, index) in unitTypes"
                        :key="index"
                        :value="unit"
                      >
                        {{ unit }}
                      </option>
                    </select>
                  </div>
                </th>

                <th>
                  Height
                  <div v-if="toggleWidththings">
                    <select
                      v-model="routeParams.widthUnitType"
                      v-if="!routeParams.widthUnitType"
                    >
                      <option
                        v-for="(unit, index) in unitTypes"
                        :key="index"
                        :value="unit"
                      >
                        {{ unit }}
                      </option>
                    </select>
                  </div>
                </th>
                <th>Qty</th>
                <th>Unit</th>
                <th>Rate</th>
                <th>Discount(%)</th>
                <th>GST(%)</th>
                <th>Amount</th>
              </tr>
            </thead>
            <div v-for="(item, index) in items" :key="index" class="itemBlock">
              <span class="ItemHeaderOfThings">
                <input
                  type="text"
                  v-model="item.header"
                  placeholder="Header Name"
                  id="HeaderOfBillItem"
                  readOnly
                />

                <p>{{ $formatToIndianCurrency(calculateTotalAmountofItem(item)) }}</p>
              </span>
              <tbody>
                <tr v-for="(row, rowIndex) in item.rows" :key="rowIndex">
                  <td>
                    {{ rowIndex + 1 }}
                  </td>
                  <td>
                    <input type="text" v-model="row.description" readOnly />
                  </td>
                  <td>
                    <input
                      type="number"
                      inputmode="numeric"
                      v-model="row.width"
                      @input="row.qty = calculateQuantity(row)"
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      inputmode="numeric"
                      v-model="row.height"
                      @input="row.qty = calculateQuantity(row)"
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      @input="row.quantity = calculateQuantity(row)"
                      v-model="row.quantity"
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      v-model="row.unitType"
                      class="text-center"
                      readOnly
                    />
                  </td>

                  <td>
                    <input
                      type="number"
                      inputmode="numeric"
                      v-model="row.rate"
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      inputmode="numeric"
                      v-model="row.discount"
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      inputmode="numeric"
                      v-model="row.GSTpercentage"
                      readOnly
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      v-model="row.amount"
                      @input="row.amount = calculateAmount(row)"
                      readOnly
                    />
                  </td>
                </tr>
              </tbody>
            </div>
          </table>
        </div>

        <div class="col-md-12 mt-2">
          <div
            class="container p-2 text-nowrap"
            style="border-radius: 5px; font-weight: bold; width: 99%; margin: 0.5%"
          >
            <div class="row">
              <div class="col-6">
                <h6 style="font-weight: bold">
                  Total Amount
                  <span style="font-size: 14px">(Excluding GST)</span>
                </h6>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <p class="text-right">₹{{ calculateFooterTotals().totalExcludingGST }}</p>
              </div>
            </div>
            <div
              v-for="(detail, gstPercentage) in calculateFooterTotals().gstDetails"
              :key="gstPercentage"
            >
              <div class="row">
                <div class="col-6 d-flex flex-column justify-content-end">
                  <span>CGST ({{ gstPercentage / 2 }}%):</span>
                  <span>SGST ({{ gstPercentage / 2 }}%):</span>
                  <!-- CGST amounts aligned at the start -->
                </div>
                <div
                  class="col-6"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: end;
                    text-align: right;
                  "
                >
                  <span class="text-right">{{ (detail / 2).toFixed(2) }}</span>
                  <span class="text-right">{{ (detail / 2).toFixed(2) }}</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h6 style="font-weight: bolder">
                  Grand Total
                  <span style="font-size: 14px">(Including GST)</span>
                </h6>
              </div>
              <div
                class="col-6 d-flex justify-content-end"
                style="border-top: 2px solid #000"
              >
                <p class="text-right" style="font-weight: bolder">
                  ₹{{ calculateGrandTotal() }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mt-2">
          <div class="container">
            <h4
              class="d-flex justify-content-center blackBgColor WhitetableHeaderHeading p-1"
            >
              Customer Note
            </h4>
            <section class="p-1">
              <pre style="min-height: 100px">{{ routeParams.customerNote }}</pre>
            </section>
          </div>
        </div>

        <div class="col-md-12 mt-2">
          <div class="container text-nowrap">
            <h4
              class="d-flex justify-content-center blackBgColor WhitetableHeaderHeading p-1"
            >
              Bank Details
            </h4>
            <div class="row mt-1">
              <div class="col-md-2">
                <label for="accountName" style="font-weight: bold">Account Name :</label>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  name="accountName"
                  id=""
                  class="border-0"
                  v-model="fetchedEstimateData['bankDetails.accountName']"
                  readonly
                  style="font-weight: bold"
                />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-2">
                <label for="accountNumber" style="font-weight: bold"
                  >Account Number :</label
                >
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  name="accountNumber"
                  id=""
                  class="border-0"
                  v-model="fetchedEstimateData['bankDetails.accountNumber']"
                  readonly
                  style="font-weight: bold"
                />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-2">
                <label for="bankName" style="font-weight: bold">Bank Name:</label>
              </div>
              <div class="col-md-6 mt-1">
                <input
                  type="text"
                  name="bankName"
                  id=""
                  class="border-0"
                  v-model="fetchedEstimateData['bankDetails.bankName']"
                  readonly
                  style="font-weight: bold"
                />
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-2">
                <label for="ifscCode" style="font-weight: bold">IFSC Code </label>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  name="ifscCode"
                  id=""
                  class="border-0"
                  v-model="fetchedEstimateData['bankDetails.IFSC']"
                  readonly
                  style="font-weight: bold"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-2">
          <div class="container" v-for="section in EstimateSectionData" :key="section.id">
            <h4
              class="d-flex justify-content-center blackBgColor WhitetableHeaderHeading p-1"
            >
              {{ section.estimateSectionHeader }}
            </h4>
            <section class="p-1">
              <pre>{{ section.estimateSectionContent }}</pre>
            </section>
          </div>
        </div>

        <div class="row border-black">
          <div class="col-md-4 mx-3" style="font-weight: 600">
            <h6 style="font-weight: 600">Thanking You,</h6>
            <br />
            <h6 style="font-weight: 600">Regards,</h6>
            <span
              >{{ fetchedEstimateData["employees.firstName"] }}
              {{ fetchedEstimateData["employees.lastName"] }}</span
            >
            <br />
            <!-- Display last name as 'empname' -->
            <span>{{ fetchedEstimateData["employees.employeeEmailId"] }}</span> <br />
            <!-- Display email as 'username' -->
            <span>{{ fetchedEstimateData["employees.mobileNumber"] }}</span>
            <!-- Display contact number -->
          </div>
        </div>

        <div class="col-md-12 mt-5">
          <div class="container">
            <h6><b>Comment</b></h6>
            <section>
              <textarea
                class="form-control position-relative bodyText"
                name=""
                id=""
                cols="150"
                rows="6"
                v-model="fetchedEstimateData.customerComment"
                placeholder="Write your comment here"
                required
              ></textarea>
            </section>
          </div>
        </div>

        <!-- Buttons -->
        <div class="footerOfEstimate bg-white mt-3">
          <div class="row justify-content-center">
            <div class="col-md-4"></div>
            <div
              class="col-md-4 d-flex footerButtons"
              v-if="
                fetchedEstimateData.status === 'Finalized' ||
                fetchedEstimateData.status === 'Sent'
              "
            >
              <button class="approveButton" @click="approveEstimateEmail">Approve</button>
              <button class="rejectButton" @click="rejectEstimateEmail">Reject</button>
            </div>
            <div class="col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MailSentModal from "@/components/Modal/MailSentModal.vue";
import axios from "axios";

export default {
  name: "EstimateApproval",
  components: {
    MailSentModal,
  },

  props: {
    leadIds: Array, // Define the expected type of passed data
  },

  data() {
    return {
      companyDetails: {},
      CompanyLogo: null,
      UUID: null,
      showMailSentModal: false,
      gstDetails: [],
      GSTOptions: [5, 12, 18, 28],
      toggleWidththings: false,
      toggleHeightthings: false,
      estimateId: "",
      routeParams: {
        clientName: "",
        propertyName: "",
        contactNo: "",
        salesPerson: "",
        email: "",
        estimateId: this.$route.params.EstimateId || "",
        estimateIndex: this.$route.params.estimateIndex || "",
        date: "",
        address: "",
        customerNote: "",
        status: "",
        widthUnitType: "",
        heightUnitType: "",
      },
      items: [],
      bankDetails: {},
      fetchedEstimateData: {},
      EstimateSectionData: [],
      unitTypes: ["mm", "cm", "meter", "inch", "feet"],
    };
  },
  mounted() {
    this.UUID = this.$route.params.UUID;
    this.fetchEstimateData();
  },

  methods: {
    openMailSentModal() {
      this.showMailSentModal = true; // Set showMailSentModal to true to open the modal
    },

    // Method to close the mail sent modal
    closeMailSentModal() {
      this.showMailSentModal = false; // Set showMailSentModal to false to close the modal
    },
    printEstimateDetailsWithoutHeader() {
      window.print();
    },

    goBack() {
      this.$router.go(-1); // Navigate back one step in the history stack
    },

    async fetchEstimateData() {
      try {
        const response = await this.fetchEstimateEmail();
        this.processFetchedData(response.data);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchEstimateEmail() {
      const response = await axios.get(
        `${this.$apiEndPoint}/estimateEmails/${this.UUID}`
      );
      return response;
    },

    processFetchedData(fetchedData) {
      this.fetchedEstimateData = fetchedData;
      this.CompanyLogo = fetchedData.companyLogo.signedUrl;
      this.companyDetails = fetchedData.companyDetails;
      this.EstimateSectionData = fetchedData.estimateSectionData;

      this.setRouteParams(fetchedData);
      this.setItemsAndGSTDetails(fetchedData);

      console.log(fetchedData);
      console.log(fetchedData.companyLogo.signedUrl);
    },

    setRouteParams(fetchedData) {
      this.routeParams = {
        estimateId: fetchedData.estimateId,
        customerNote: fetchedData.customerNote,
        clientName: fetchedData["leads.name"],
        propertyName: fetchedData["property.name"],
        propertyId: fetchedData["property.id"],
        contactNo: fetchedData["leads.contactNo"] || "",
        salesPerson: fetchedData.salesPersonId || "",
        email: fetchedData["leads.email"],
        date: fetchedData.date || "",
        address: this.formatAddress(fetchedData),
        leadId: fetchedData.leadId,
      };
      console.log(fetchedData["leads.name"]);
    },

    formatAddress(fetchedData) {
      const primaryAddress = fetchedData["addresses.address"]
        ? `${fetchedData["addresses.address"] ?? ""} ${
            fetchedData["addresses.city"] ?? ""
          } ${fetchedData["addresses.state"] ?? ""}`
        : `${fetchedData["leadAddresses.address"] ?? ""} ${
            fetchedData["leadAddresses.city"] ?? ""
          } ${fetchedData["leadAddresses.state"] ?? ""}`;
      return primaryAddress;
    },

    setItemsAndGSTDetails(fetchedData) {
      if (fetchedData?.lineItems) {
        this.items = fetchedData.lineItems.map((item) => ({
          header: item.header,
          rows: item.Rows.map((row) => ({
            ...row,
            GSTpercentage: row.GSTpercentage,
          })),
        }));

        this.GSTOptions.forEach((option) => {
          const gstData = fetchedData.GSTgrouping.find((group) => group.gst === option);
          if (gstData) {
            this.gstDetails.push({
              gstPercentage: gstData.option,
              detail: gstData.amount,
            });
          }
        });
      }
    },

    async bankDetailss(CompanyId) {
      const bankResponse = await axios.get(
        `${this.$apiEndPoint}/bankDetails/byEntityId/${CompanyId}/Company/`
      );
      this.bankDetails = bankResponse.data;
    },
    calculateQuantity(row) {
      const width = parseFloat(row.width);
      const height = parseFloat(row.height);
      let quantity = width * height; // Initial quantity calculation
      const unitType = this.routeParams.widthUnitType || this.routeParams.heightUnitType;
      switch (unitType) {
        case "mm":
          quantity /= 92903; // Convert mm to sqft
          break;
        case "cm":
          quantity /= 929.03; // Convert cm to sqft
          break;
        case "meter":
          quantity *= 10.7639; // Convert meter to sqft
          break;
        case "inch":
          quantity /= 144; // Convert inch to sqft
          break;
        case "feet":
          // Already in sqft, no need to convert
          break;
        default:
          break;
      }
      return isNaN(quantity) ? row.quantity : quantity.toFixed(2);
    },
    calculateAmount(row) {
      const rate = parseFloat(row.rate) || 0;
      const quantity = parseFloat(row.quantity) || 0;
      const discount = parseFloat(row.discount || 0);
      const GSTpercentage = parseFloat(row.GSTpercentage || 0);
      console.log(GSTpercentage);

      // Calculate amount
      let amount = rate * quantity - (discount * rate * quantity) / 100;

      // Ensure amount is a number and return it as a fixed-point number
      return isNaN(amount) ? 0 : parseFloat(amount.toFixed(2));
    },

    calculateTotalAmountofItem(item) {
      let totalAmount = 0;
      item.rows.forEach((row) => {
        totalAmount += parseFloat(row.amount || 0);
      });
      return totalAmount;
    },

    calculateFooterTotals() {
      let totalsByGST = {}; // Object to store totals categorized by GST percentage
      let totalExcludingGST = 0;
      let CGST = 0;
      let SGST = 0;
      // Calculate totals only if there are items
      if (this.items.length > 0) {
        this.items.forEach((item) => {
          item.rows.forEach((row) => {
            const amount = parseFloat(row.amount || 0);
            totalExcludingGST += amount;
            const gst = parseFloat(row.GSTpercentage || 0);
            const gstAmount = (gst / 100) * amount;

            // Grouping GST amounts by percentage
            if (!totalsByGST[gst]) {
              totalsByGST[gst] = 0;
            }
            totalsByGST[gst] += gstAmount;
          });
        });

        // Calculate CGST and SGST for each category
        for (let gst in totalsByGST) {
          const gstAmount = totalsByGST[gst];
          CGST += gstAmount / 2;
          SGST += gstAmount / 2;
        }
      }
      const grandTotal = totalExcludingGST + CGST + SGST;
      return {
        totalExcludingGST: isNaN(totalExcludingGST) ? 0 : totalExcludingGST.toFixed(2),
        CGST: isNaN(CGST) ? 0 : CGST.toFixed(2),
        SGST: isNaN(SGST) ? 0 : SGST.toFixed(2),
        gstDetails: totalsByGST, // Object containing GST details
        grandTotal: isNaN(grandTotal) ? 0 : grandTotal.toFixed(2),
      };
    },

    calculateGrandTotal() {
      const { totalExcludingGST, CGST, SGST } = this.calculateFooterTotals();

      const grandTotal =
        parseFloat(totalExcludingGST || 0) +
        parseFloat(CGST || 0) +
        parseFloat(SGST || 0);

      // Ensure grandTotal is a number, rounding to two decimal places
      return isNaN(grandTotal) ? 0 : Math.round(grandTotal * 100) / 100;
    },

    // Calculate final total including GST
    calculateFinalTotal() {
      const { grandTotal } = this.calculateFooterTotals();
      return parseFloat(grandTotal).toFixed(2);
    },

    //Approve estimateEmail
    async approveEstimateEmail() {
      console.log(this.fetchedEstimateData.customerComment);
      alert("Are you sure Approve your estimate");
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/estimateEmails/${this.fetchedEstimateData.id}`,
          {
            status: "Approved",
            customerComment: this.fetchedEstimateData.customerComment,
          }
        );
        this.fetchEstimateData();
        alert("Your estimate Approved successfully");
        console.log(response.data);
      } catch (error) {
        this.$handleError(error);
      }
    },

    //Reject estimateEmail
    async rejectEstimateEmail() {
      alert("Are you sure Reject your estimate");
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/estimateEmails/${this.fetchedEstimateData.id}`,
          {
            status: "Rejected",
            customerComment: this.fetchedEstimateData.customerComment,
          }
        );
        this.fetchEstimateData();
        alert("Your estimate Rejected successfully");
        console.log(response.data);
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

.companyName {
  color: #ffffff;
  display: flex;
}

.companyName h4 {
  margin-top: 1%;
}

.estimate {
  margin-left: 55%;
  margin-top: 1%;
}

pre {
  font-size: 14px;
  color: #000000;
  font-family: "Inter", sans-serif !important;
  word-break: break-all;
  white-space: pre-wrap;
}

.FinalEstimateHeader label,
.FinalEstimateHeader p {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  padding: 2px;
}

.active {
  text-decoration: underline;
}

.FinalEstimateHeader {
  padding: 0 5%;
  border-radius: 5px;
}

.FinalEstimateHeader img {
  width: 6%;
  margin: 0 2%;
  /* Adjust margin as needed */
  aspect-ratio: 1/1;
  /* Maintain aspect ratio */
  object-fit: contain;
  cursor: pointer;
}

.FinalEstimateHeader img:hover {
  width: 7%;
  /* To make images responsive */
}

.glass-effect {
  position: relative;
}

.glass-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.EstimateContent {
  margin: 0 10%;
  padding: 0 2%;
  background-color: #ffffff;
}

.EstimateBill::-webkit-scrollbar {
  display: none;
}

.EstimateBill {
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
}

.tableOfEstimate thead tr .tableOfEstimate thead tr th {
  color: #000000;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f8f8f8;
  text-align: center;
}

.tableOfEstimate tbody {
  width: 100%;
}

.tableOfEstimate tbody::-webkit-scrollbar {
  display: none;
}

.tableOfEstimate tbody tr,
.tableOfEstimate thead tr {
  display: flex;
}

.tableOfEstimate thead th,
.tableOfEstimate tbody tr td {
  min-width: 9.5%;
  max-width: 9.5%;
  text-align: center;
}

.tableOfEstimate thead th:nth-child(1),
.tableOfEstimate tbody tr td:nth-child(1) {
  min-width: 60px;
  max-width: 60px;
}

.tableOfEstimate tbody tr td input[type="number"] {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
}

.tableOfEstimate tbody tr td input[type="text"] {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
}

.tableOfEstimate tbody td:nth-child(2),
.tableOfEstimate thead th:nth-child(2) {
  min-width: 20.8%;
  max-width: 20.8%;
  word-break: break-all;
}

.itemBlock {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.ItemHeaderOfThings p {
  position: relative;
  height: 5px;
  color: #000000;
  font-size: 15px;
  background-color: #ccc;
  font-weight: bolder;
}

.itemBlock::-webkit-scrollbar {
  display: none;
}

.itemBlock span button {
  background: transparent;
  display: flex;
  padding: 0.5%;
  border-radius: 10px;
  color: #000000;
  outline: none;
  border: none;
}

#itemRowEstimate {
  border: 1px solid #edeae9;
}

.itemBlock #HeaderOfBillItem {
  background-color: transparent;
  width: 100%;
  margin-left: 45%;
  border: none;
  outline: none;
  color: #000000;
  font-size: 16px;
  font-weight: bolder;
  text-align: left;
}

#itemAdding {
  display: flex;
  background-color: #084f5b;
  margin-left: 1%;
  padding: 0.5% 1%;
  border-radius: 10px;
  color: whitesmoke;
  cursor: pointer;
  border: 1px solid #ffaa23;
}

#itemrowAdding {
  display: flex;
  color: #000000;
  margin-left: 1%;
  padding: 0.5% 1%;
  border-radius: 10px;
  white-space: nowrap;
  cursor: pointer;
  width: 10%;
  background-color: transparent;
  border: none;
  outline: none;
}

.toggleWidththings,
.toggleHeightthings {
  position: fixed;
  background-color: #ffffff;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 10px 20px;
  letter-spacing: 2px;
}

.toggleWidththings p,
.toggleHeightthings p {
  padding: 4px 8px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: #000000;
  font-weight: 100;
}

/* Responsive table styles */
.tableOfEstimate {
  width: 100%;
  border-collapse: collapse;
}

.tableOfEstimate tbody tr {
  padding-top: 2px;
  padding-bottom: 2px;
}

.subHeaderHeadingBgColor {
  background-color: #000000;
}

.ItemHeaderOfThings {
  display: flex;
  white-space: nowrap;
  background-color: #ccc;
  height: 35px;
  width: 100%;
  font-size: 16px;
  font-weight: bolder;

  align-items: center;
  padding: 2px !important;
}

.ItemHeaderOfThings input {
  flex: 1;
  text-align: center;
}

.dropdownToggle {
  cursor: pointer;
}

/* Responsive table container */
.table-responsive {
  overflow-x: auto;
}

button {
  margin-top: 10px;
}

.container {
  font-size: 14px;
}

.FinalEstimate {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #494949;
  font-family: "Inter", sans-serif;
}

.approveButton {
  letter-spacing: 2px;
  margin: 5px 10px;
  background-color: #0000ff;
  padding: 10px 25px;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #ffffff;
}

.rejectButton {
  letter-spacing: 2px;
  margin: 5px 15px;
  background-color: red;
  padding: 10px 25px;
  font-size: 12px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #ffffff;
}

#companylogoInput {
  height: 80px;
  width: fit-content;
  border: 1px solid #ccc;

  border-radius: 5px;
  outline: none;
  max-width: 300px;
}
/* Styles for responsiveness */
@media only screen and (max-width: 768px) {
  .FinalEstimateHeader img {
    width: 20%;
    /* To make images responsive */
    margin: 0 5%;
    /* Adjust margin as needed */
    aspect-ratio: 1/1;
    /* Maintain aspect ratio */
    object-fit: contain;
  }

  .tableOfEstimate tbody td,
  .tableOfEstimate thead th {
    white-space: nowrap;
    min-width: 120px;
    position: relative;
  }

  label {
    white-space: nowrap;
    flex: 1;
  }

  .customer-details {
    margin-top: -10%;
  }

  .tableOfEstimate tbody tr,
  .tableOfEstimate thead tr {
    display: flex;
    overflow: scroll;
  }

  .footerOfEstimate {
    margin-bottom: 50px;
  }

  .footerButtons {
    margin-left: 20%;
  }
}
</style>
