<template>
  <div class="modal fade" id="tableDetailModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content custom-modal-content">
        <div class="modal-header custom-modal-header">
          <h5 class="modal-title">Details</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body custom-modal-body">
          <ul class="list-group">
            <li
              v-for="(item, index) in details"
              :key="index"
              class="list-group-item custom-list-item"
            >
              <div class="label">
                <strong>{{ item.label }}</strong>
              </div>
              <div class="colon">:</div>
              <div class="value text-ellipsis">
                <span>{{ item.value }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    details: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
/* Custom Modal Styles */
.custom-modal-content {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.custom-modal-header {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  padding: 15px;
  border-radius: 10px 10px 0 0;
}

.custom-modal-body {
  padding: 20px;
  max-height: 400px;
  overflow-y: auto;
}

/* List Item Styling */
.custom-list-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.label {
  flex: 1;
  text-align: left;
  font-weight: bold;
  color: #343a40;
}

.colon {
  flex: 0;
  margin-right: 10px;
  text-align: center;
  font-weight: bold;
  color: #6c757d;
}

.value {
  flex: 2;
  text-align: left;
  color: #495057;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Modal Close Button */
.btn-close {
  background-color: transparent;
  border: none;
}

/* Modal Borders and Shadows */
.modal-content {
  border: 1px solid #dee2e6;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}
</style>
