<template>
  <Header />

  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="FormatTable" v-if="showUnclaimedList">
        <div class="row headerOfTable d-flex">
          <div class="col-md-12 d-flex align-content-center">
            <div class="col-md-6 d-flex">
              <div class="col-md-3 text-nowrap SubheadingUnderLine">
                <h5>Purchase Order</h5>
              </div>
              <div class="col-md-2 ms-3">
                <img
                  src="../../../assets/icons/blueAddicon.png"
                  alt=""
                  id="addicon"
                  @click="goToPurchaseCreate()"
                />
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <div class="col-md-3 ImportExport" style="visibility: hidden">
                <div class="row" style="cursor: pointer">
                  <div class="col-md-4 tableTextEvenRow">Import</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/import.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>
              <div class="col-md-3 ImportExport" style="visibility: hidden">
                <div class="row" style="cursor: pointer">
                  <div class="col-md-4 tableTextEvenRow" @click="exportToCSV">Export</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/export.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>

              <div class="col-md-1">
                <img
                  src="../../../assets/icons/archive.png"
                  alt=""
                  id="ArchiveIcon"
                  @click="handleArchiveClick"
                />
              </div>
              <div class="col-md-4 searchboxOfTable">
                <div class="search-box">
                  <img
                    src="../../../assets/icons/search.png"
                    alt="Search Icon"
                    id="SerchIconOfTable"
                  />
                  <input
                    type="text"
                    name="search"
                    id=""
                    v-model="searchString"
                    @input="searchLeads"
                  />
                </div>
                <span>
                  <img
                    src="../../../assets/icons/filter.png"
                    alt="Search Icon"
                    id="FilterIconOfTable"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrapper">
          <table class="tableFormat">
            <caption class="d-none">
              Table
            </caption>
            <thead>
              <tr>
                <th class="">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectAll"
                      @change="selectAllRows"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-2"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="ArchiveLeads('Archive')">Archive</p>
                    <p @click="deleteLead('ArchiveDelete')">Delete</p>
                  </div>
                </th>
                <th class="tableHeaderHeading">PO Number</th>
                <th class="tableHeaderHeading">Reference</th>
                <th class="tableHeaderHeading">Date</th>
                <th class="tableHeaderHeading">Vendor Name</th>
                <th class="tableHeaderHeading">Project Name</th>
                <th class="tableHeaderHeading">Status</th>
                <th class="tableHeaderHeading text-center">Amount</th>
                <th class="tableHeaderHeading text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in displayedData" :key="index">
                <td class="bodyText">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectedCustomers"
                      :value="data.id"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-2"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="ArchiveLead(data, index)">Archive</p>
                    <p @click="DeleteLead(data, index)">Delete</p>
                  </div>
                </td>
                <td
                  @click="openModal(data.id)"
                  data-bs-target="#PODetailsModal"
                  data-bs-toggle="modal"
                >
                  {{ data.poNumber }}
                </td>
                <td class="text-left">
                  <span v-if="data['purchaseRequests.prNumber']"
                    >{{ data["purchaseRequests.prNumber"] }}
                  </span>
                  <span style="color: purple" v-else>Direct PO</span>
                </td>
                <td class="text-left">{{ this.$formatDateDMY(data.date) }}</td>
                <td class="text-left">{{ data["vendors.companyName"] }}</td>
                <td class="text-left">{{ data["projects.projectName"] }}</td>
                <td :class="statusClass(data.poStatus)">
                  <select
                    v-if="
                      data.poStatus === 'Approved' ||
                      data.poStatus === 'Semi Delivered' ||
                      data.poStatus === 'Delivered' ||
                      data.poStatus === 'Ordered'
                    "
                    v-model="data.poStatus"
                    @change="handleStatusChangeOfPo(data.id, data.poStatus)"
                    class="form-select form-select-sm"
                    :class="statusClass(data.poStatus)"
                    style="width: max-content; color: inherit; background-color: inherit"
                  >
                    <option value="Delivered" :style="optionStyle('Delivered')">
                      Delivered
                    </option>
                    <option value="Approved" :style="optionStyle('Approved')">
                      Approved
                    </option>
                    <option value="Semi Delivered" :style="optionStyle('Semi Delivered')">
                      Semi-Delivered
                    </option>
                    <option value="Ordered" :style="optionStyle('Ordered')">
                      Ordered
                    </option>
                    <!-- Add more status options here if needed -->
                  </select>

                  <span v-else> {{ data.poStatus }}</span>
                </td>
                <td class="text-end">{{ $formatToIndianCurrency(data.totalAmount)}}</td>
                <td class="text-center">
                  <img
                    class="ms-3"
                    src="../../../assets/icons/view.png"
                    alt=""
                    style="width: 17px; height: 17px; cursor: pointer"
                    @click="openModal(data.id)"
                    data-bs-toggle="modal"
                    data-bs-target="#PODetailsModal"
                  />
                  <img
                    class="ms-3"
                    src="../../../assets/icons/pinkDownold.png"
                    alt=""
                    style="width: 17px; height: 17px; cursor: pointer"
                    @click="handleDownload(data.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginationOfTable">
          <div class="pageControlsTable">
            <img
              src="../../../assets/icons/blueLeftarrow.png"
              @click="goToPreviousPage"
              :disabled="!hasPreviousPage"
              alt=""
            />
            <div class="page-numbers">
              <span
                v-for="pageNumber in pagination"
                :key="pageNumber"
                :class="{
                  'page-number': true,
                  'current-page': pageNumber === currentPage,
                }"
                @click="goToPage(pageNumber)"
                >{{ pageNumber }}</span
              >
            </div>

            <img
              src="../../../assets/icons/blueRightarrow.png"
              @click="goToNextPage"
              :disabled="!hasNextPage"
              alt=""
            />
          </div>

          <div class="entries-info">
            <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
          </div>
        </div>
      </div>
      <div class="FormatTable" v-if="showArchiveList">
        <div class="row headerOfTable d-flex">
          <div class="col-md-12 d-flex align-content-center">
            <div class="col-md-6 d-flex">
              <div class="col-md-3 text-nowrap active-SubHeading">
                <h5>Archived Purchase Order</h5>
              </div>
              <div class="col-md-2" style="visibility: hidden">
                <img
                  src="../../../assets/icons/blueAddicon.png"
                  alt=""
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                  data-bs-whatever="@mdo"
                  id="addicon"
                />
              </div>
              <div class="col-md-1">
                <img
                  src="../../../assets/icons/unarchive.png"
                  alt=""
                  id="ArchiveIcon"
                  @click="handleArchiveClick"
                />
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <div class="col-md-3 ImportExport">
                <div class="row" style="visibility: hidden">
                  <div class="col-md-4 tableTextEvenRow">Import</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/import.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>
              <div class="col-md-3 ImportExport" style="visibility: hidden">
                <div class="row" style="cursor: pointer">
                  <div class="col-md-4 tableTextEvenRow" @click="exportToCSV()">
                    Export
                  </div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/export.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>

              <div class="col-md-1"></div>
              <div class="col-md-4 searchboxOfTable">
                <div class="search-box">
                  <img
                    src="../../../assets/icons/search.png"
                    alt="Search Icon"
                    id="SerchIconOfTable"
                  />
                  <input
                    type="text"
                    name="search"
                    id=""
                    v-model="searchString"
                    @input="searchArchiveLeads"
                  />
                </div>
                <span>
                  <img
                    src="../../../assets/icons/filter.png"
                    alt="Search Icon"
                    id="FilterIconOfTable"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrapper">
          <table class="tableFormat">
            <caption class="d-none">
              Table
            </caption>
            <thead>
              <tr>
                <th class="">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectAll"
                      @change="selectAllArchiveRows"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-2"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="ArchiveLeads('UnArchive')">UnArchive</p>
                    <p @click="deleteLead('UnArchiveDelete')">Delete</p>
                  </div>
                </th>
                <th class="tableHeaderHeading">PO Number</th>
                <th class="tableHeaderHeading">Reference</th>
                <th class="tableHeaderHeading">Date</th>
                <th class="tableHeaderHeading">Vendor Name</th>
                <th class="tableHeaderHeading">Project Name</th>

                <th class="tableHeaderHeading">Status</th>
                <th class="tableHeaderHeading text-center">Amount</th>
                <th class="tableHeaderHeading text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in displayedArchiveData" :key="index">
                <td class="bodyText">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectedCustomers"
                      :value="data.id"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-2"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="ArchiveLead(data, index)">Archive</p>
                    <p @click="DeleteLead(data, index)">Delete</p>
                  </div>
                </td>
                <td
                  @click="openModal(data.id)"
                  data-bs-target="#PODetailsModal"
                  data-bs-toggle="modal"
                >
                  {{ data.poNumber }}
                </td>
                <td class="text-left">{{ data["purchaseRequests.prNumber"] }}</td>
                <td class="text-left">{{ this.$formatDateDMY(data.date) }}</td>
                <td class="text-left">{{ data["vendors.companyName"] }}</td>
                <td class="text-left">{{ data["projects.projectName"] }}</td>

                <td>{{ data.poStatus }}</td>
                <td class="text-end">{{ $formatToIndianCurrency(data.totalAmount)}}</td>
                <td class="text-center">
                  <img
                    class="ms-3"
                    src="../../../assets/icons/view.png"
                    alt=""
                    style="width: 17px; height: 17px; cursor: pointer"
                    @click="openModal(data.id)"
                    data-bs-toggle="modal"
                    data-bs-target="#PODetailsModal"
                  />
                  <img
                    class="ms-3"
                    src="../../../assets/icons/pinkDownold.png"
                    alt=""
                    style="width: 17px; height: 17px; cursor: pointer"
                    @click="handleDownload(data.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginationOfTable">
          <div class="pageControlsTable">
            <img
              src="../../../assets/icons/blueLeftarrow.png"
              @click="goToArchivePreviousPage"
              :disabled="!hasArchivePreviousPage"
              alt=""
            />
            <div class="page-numbers">
              <span
                v-for="pageNumber in paginationArchive"
                :key="pageNumber"
                :class="{
                  'page-number': true,
                  'current-page': pageNumber === currentPage,
                }"
                @click="goToPage(pageNumber)"
                >{{ pageNumber }}</span
              >
            </div>

            <img
              src="../../../assets/icons/blueRightarrow.png"
              @click="goToArchiveNextPage"
              :disabled="!hasArchiveNextPage"
              alt=""
            />
          </div>

          <div class="entries-info">
            <span class="entries-text"
              >Entries: {{ displayedArchiveData.length }} Rows</span
            >
          </div>
        </div>
      </div>
    </div>
    <PODetailsModal :PurchaseOrderId="selectedPRId" ref="PODetailsModal" />
  </div>
</template>

<script>
import axios from "axios";
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import PODetailsModal from "../../Modal/PODetailsModal.vue";
let pageFlag = true;

export default {
  name: "PurchaseOrderList",
  components: {
    Header,
    Nav,
    PODetailsModal,
  },
  data() {
    return {
      searchString: "",
      totalCount: null,
      totalArchiveCount: null,
      leadDropdownStates: {},
      tabledropdownEdits: false,
      showClaimedList: false,
      selectAll: false,
      TableData: [],
      ArchiveData: [],
      selectedCustomers: [],
      pageSize: 50,
      currentPage: 1,
      showUnclaimedList: true,
      showArchiveList: false,
      selectedEmployees: [],
      searchResults: [],
      leadTypePage: true,
      dropdownOpen: false,
      statusDropdownOpen: false,
      selectedPRId: null,
    };
  },
  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.totalCount / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.totalArchiveCount / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.totalCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.totalArchiveCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      return this.TableData;
    },
    displayedArchiveData() {
      return this.ArchiveData;
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleDownload(id) {
      this.selectedPRId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.PODetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },
    openModal(leadId) {
      console.log(this.selectedPRId);
      this.selectedPRId = leadId;
      console.log(this.selectedPRId); // Set the selected lead ID when a lead is clicked
    },
    exportToCSV() {
      // Combine displayedData and displayedArchiveData based on your current view
      const allData = this.showArchiveList ? this.ArchiveData : this.TableData;

      // Extract only the fields you want to include in the CSV
      const filteredData = allData.map((data) => ({
        /*   Name: `${data.salutation} ${data.name}`, */
        Saturation: data.salutation,
        Name: data.name,
        "Customer ID": data.purchaseRequestNumber,
        "Contact No": data.contactNo,
        "Email ID": data.email,
        "Property Address": data["addresses.address"],
      }));

      // Convert filtered data to CSV format
      const csvContent = this.convertToCSV(filteredData);

      // Trigger download
      this.downloadCSV(csvContent);
    },

    convertToCSV(data) {
      const headers = Object.keys(data[0]).join(",") + "\n";
      const csvData = data.map((item) => Object.values(item).join(",")).join("\n");
      return headers + csvData;
    },
    goToPurchaseCreate() {
      this.$router.push({
        name: "CreatePurchaseOrder",
      });
    },

    downloadCSV(csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    goToPreviousPage() {
      if (this.hasPreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToNextPage() {
      if (this.hasNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    goToArchivePreviousPage() {
      if (this.hasArchivePreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToArchiveNextPage() {
      if (this.hasArchiveNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    changePage(delta) {
      this.selectAll = false;
      this.lastPages = this.currentPage;
      this.currentPage += delta;
      this.fetchData();
      this.fetchArchiveData();
      this.selectedCustomers = [];
    },
    //footer pagination
    goToPage(pageNumber) {
      this.selectAll = false;
      this.currentPage = pageNumber;
      console.log(this.currentPage);
      this.fetchData();
      this.fetchArchiveData();
      this.selectedCustomers = [];
    },
    SigleLeadThingstd(lead) {
      this.selectedLead = lead;
      this.leadDropdownStates[lead.id] = !this.leadDropdownStates[lead.id];
    },
    toggletabledrowpdown() {
      this.tabledropdownEdits = !this.tabledropdownEdits;
    },

    handleArchiveClick() {
      this.fetchArchiveData();
      this.showUnclaimedList = !this.showUnclaimedList;
      this.showArchiveList = !this.showArchiveList;
    },
    async fetchData() {
      let searchData = {
        searchString: this.searchString,
        pageFlag,
      };

      const hasSearchData = searchData.searchString;
      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
      }
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/purchaseOrder/getAllPo/${this.currentPage}`,
          searchData
        );
        this.totalCount = response.data.count;
        this.TableData = response.data.rows;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchArchiveData() {
      let searchData = {
        searchString: this.searchString,
        pageFlag,
      };

      const hasSearchData = searchData.searchString;
      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
      }
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/purchaseOrder/getAllPo/${this.currentPage}/archived`,
          searchData
        );
        this.totalArchiveCount = response.data.count;
        this.ArchiveData = response.data.rows;
      } catch (error) {
        this.$handleError(error);
      }
    },
    //select and unselect checkbox function
    selectAllRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedData.map(
          (purchaseRequest) => purchaseRequest.id
        );
      } else {
        this.selectedCustomers = [];
      }
    },
    selectAllArchiveRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedArchiveData.map(
          (purchaseRequest) => purchaseRequest.id
        );
      } else {
        this.selectedCustomers = [];
      }
    },

    // Method to delete the selected lead
    DeleteLead(data, index) {
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);
      axios
        .delete(`${this.$apiEndPoint}/purchaseOrder/deletePo`, {
          data: { id: [data.id] },
        })
        // Pass an array with a single ID to match the API's expected format
        .then((response) => {
          console.log("Lead deleted successfully:", response.data);
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    ArchiveLead(data, index) {
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);
      console.log(this.ArchiveData);
      axios
        .put(`${this.$apiEndPoint}/purchaseOrder/archive`, { id: [data.id] })
        // Pass an array with a single ID to match the API's expected format
        .then((response) => {
          console.log("Lead deleted successfully:", response.data);
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    searchArchiveLeads() {
      pageFlag = true;
      this.fetchArchiveData();
    },
    //for Serch api for Unclaimleads
    searchLeads() {
      pageFlag = true;
      this.fetchData();
    },

    //for all selected lead Unclaim and delete api
    ArchiveLeads(action) {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (purchaseRequest) => !this.selectedCustomers.includes(purchaseRequest.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (purchaseRequest) => !this.selectedCustomers.includes(purchaseRequest.id)
        );
        this.selectAll = false;
      }
      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .put(`${this.$apiEndPoint}/purchaseOrder/archive`, payload)
        .then((response) => {
          console.log("Leads updated successfully:", response.data);

          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.selectAll = false;
          this.tabledropdownEdits = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    deleteLead(action) {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (purchaseRequest) => !this.selectedCustomers.includes(purchaseRequest.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (purchaseRequest) => !this.selectedCustomers.includes(purchaseRequest.id)
        );
        this.selectAll = false;
      }
      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .delete(`${this.$apiEndPoint}/purchaseOrder/deletePo`, { data: payload })
        .then((response) => {
          console.log("Leads deleted successfully:", response.data);
          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.selectAll = false;
          this.tabledropdownEdits = false;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    statusClass(status) {
      switch (status) {
        case "Approved":
          return "approvedText";
        case "Semi Ordered":
          return "semi-deliveredText";
        case "Ordered":
          return "orderedText";
        case "Delivered":
          return "deliveredText";
        case "Rejected":
          return "rejectedText";
        case "Pending":
          return "pendingText";
        case "Payment Pending":
          return "pendingText";
        case "Payment Done":
          return "approvedText";
        default:
          return "";
      }
    },
    async handleStatusChangeOfPo(id, newStatus) {
      try {
        const response = await axios.put(`${this.$apiEndPoint}/purchaseOrder/${id}`, {
          poStatus: newStatus,
        });

        if (response.status === 200) {
          console.log("Status updated successfully:", response.data);
          this.$toast(`Status updated to ${newStatus}`, "success");
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    optionStyle(status) {
      switch (status) {
        case "Payment Pending":
          return "color: #ffd159;"; // Yellow color
        case "Payment Done":
          return "color: #38b525;"; // Green color
        case "Delivered":
          return "color: #32587a;"; // Dark blue color
        case "Approved":
          return "color: #38b525;"; // Green color
        case "Semi Ordered":
          return "color: #6d756b;"; // Gray color
        case "Ordered":
          return "color: #2381f3;"; // Blue color
        case "Semi Delivered":
          return "color: #6d756b;"; // Gray color

        default:
          return "";
      }
    },
    //featch all Unclaim lead Data

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style scoped>
.btn-priority-low {
  background-color: #f4e8fb;
  color: #ba69e2;
}

.btn-priority-medium {
  background-color: #ecebec;
  color: #7e7882;
}

.btn-priority-high {
  background-color: #feabab;
  color: #fd0000;
}

.btn-priority-Normal {
  background-color: #eaf3f1;
  color: #4b9b86;
}

#LeadFollowDate {
  color: rgb(107, 60, 60);
}
.FormatTable {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tableFormat:nth-child(2) {
  cursor: pointer;
}

.headerOfTable {
  width: 96%;
  padding: 0.5% 1%;
}

.table-wrapper {
  width: 100%;
  padding: 0 1%;
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100% - 74px);
  padding-bottom: 20px;
}

.tableFormat {
  width: 100%;
  border-collapse: collapse;
}
.tableFormat th:nth-child(1),
.tableFormat td:nth-child(1) {
  display: inline-flex;

  background-color: #ffffff;
}
.tableFormat thead {
  background-color: #bcbfc6;
  position: sticky;
  z-index: 99;
  top: 0;
}

.tableFormat thead tr th:not(:first-child) {
  background-color: #bcbfc6;
}
.tableFormat th,
.tableFormat td {
  padding: 8px;
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
}

#blackThreeDots {
  width: 15px;
  height: 14px;
  margin-left: 30%;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
}

.tableHeaderCheckbox,
.bodyCheckbox {
  width: 200px; /* Adjust width as needed */
}

.tableHeaderHeading {
  min-width: 150px; /* Minimum width to ensure readability */
  text-align: center; /* Center align headers */
}

.bodyText {
  text-align: center; /* Center align body text */
}

.custom-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
}
.table-wrapper
  .tableFormat
  tbody
  tr:not(:first-child):nth-child(even)
  td:not(:first-child) {
  background-color: #f8f8f8;
}
.ImportExport {
  visibility: hidden;
}

#addicon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

#ImportIcon {
  width: 20px;
  cursor: pointer;
}

#ArchiveIcon {
  width: 15px;
  cursor: pointer;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
  align-items: center;
}

#FilterIconOfTable {
  width: 20%;
  height: 5%;
  margin-left: 10%;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}
@media screen and (max-width: 768px) {
  .tableFormat th,
  .tableFormat td {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .tableHeaderCheckbox,
  .bodyCheckbox {
    width: 150px; /* Adjust width for smaller screens */
  }
  #addicon {
    width: 100%;
  }

  .table-wrapper {
    max-height: 90%;
    /* Adjust as needed */
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }

  .paginationOfTable {
    bottom: 0.5%;
  }
}
</style>
