<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="OnSiteProjectHeader">
          <div class="OnSiteProjectComponentHeader">
            <div class="DesignProjectComponentHeader p-2">
              <img
                src="../../../assets/icons/black_OnSite.png"
                alt=""
                id="DesignProjectHeaderImage"
              />
              <div class="DesignProjectHeader">
                <section class="DesignProjectHeaderRow1">
                  <input
                    type="text"
                    name=""
                    id="ProjectName"
                    v-model="form.name"
                    class="mainHeading"
                    style="letter-spacing: 1px"
                    @blur="submitDesignForm"
                  />
                </section>
                <div class="DesignProjectHeaderRow2">
                  <section class="OnsiteProjectHeaderRow2 WEB">
                    <h6
                      @click="setActiveTab('contractorDetails')"
                      :class="{
                        'active-SubHeading': activeTab === 'contractorDetails',
                        'inactive-SubHeading': activeTab !== 'contractorDetails',
                      }"
                    >
                      Contractor Details
                    </h6>
                    <h6
                      class="ms-3"
                      @click="setActiveTab('Quotations')"
                      :class="{
                        'active-SubHeading': activeTab === 'Quotations',
                        'inactive-SubHeading': activeTab !== 'Quotations',
                      }"
                    >
                      Quotations
                    </h6>
                  </section>
                  <select
                    class="mt-2 ms-1 MOBILE"
                    v-model="activeTab"
                    style="border-radius: 5px"
                  >
                    <option value="contractorDetails">Contractor Details</option>
                    <option value="Quotations">Quotations</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="headerbelowcontent">
            <div v-if="activeTab === 'contractorDetails'">
              <div class="contractorDetails">
                <div class="contractorPrimaryDetails p-3">
                  <p class="row redSubHeading">Primary Contact Details</p>
                  <div class="row col-md-12 mb-3">
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="contractorID" class="form-label">Contractor ID</label>
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="contractorID"
                          v-model="form.contractorNumber"
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="contactNumber" class="form-label"
                          >Contact number</label
                        >
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="contactNo"
                          v-model="form.contactNo"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="category" class="form-label">Category</label>
                      </div>
                      <div class="col-md-10">
                        <select
                          class="form-control"
                          id="category"
                          v-model="form.contractorCategory"
                        >
                          <option
                            v-for="category in categories"
                            :key="category.id"
                            :value="category.id"
                          >
                            {{ category.categoryName }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row col-md-12 mb-3">
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="emailID" class="form-label">Email ID</label>
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="emailID"
                          v-model="form.email"
                        />
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="address" class="form-label">Address</label>
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="address"
                          v-model="form.address"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="city" class="form-label">City</label>
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="city"
                          v-model="form.city"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row col-md-12 mb-3">
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="state" class="form-label">State</label>
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="state"
                          v-model="form.state"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="pinCode" class="form-label">Pin Code</label>
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="pinCode"
                          v-model="form.pinCode"
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <p class="row redSubHeading">Secondary Contact Details</p>
                  <div class="row col-md-12 mb-3">
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="contactPerson" class="form-label"
                          >Contact Person</label
                        >
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="contactPerson"
                          v-model="form.secondaryContactName"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="contactEmailID" class="form-label">Email ID</label>
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="contactEmailID"
                          v-model="form.secondaryContactEmail"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="contactNumber" class="form-label"
                          >Contact number</label
                        >
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="contactNumber"
                          v-model="form.secondaryContactNumber"
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <p class="row redSubHeading mt-2">Bank Details</p>
                  <div class="row col-md-12 mb-3">
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="beneficiaryName" class="form-label"
                          >Beneficiary Name</label
                        >
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="beneficiaryName"
                          v-model="bankDetails.beneficiaryName"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="accountNumber" class="form-label"
                          >Account Number</label
                        >
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="accountNumber"
                          v-model="bankDetails.accountNumber"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="ifscCode" class="form-label">IFSC Code</label>
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="ifscCode"
                          v-model="bankDetails.IFSC"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="branchName" class="form-label">Bank Name</label>
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="branchName"
                          v-model="bankDetails.bankName"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="col-md-4">
                        <label for="bankAddress" class="form-label">Bank Address</label>
                      </div>
                      <div class="col-md-10">
                        <input
                          type="text"
                          class="form-control"
                          id="bankAddress"
                          v-model="bankDetails.branchAddress"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <footer class="footer">
                  <button class="btn btn-sm btn-primary" @click="saveForm">Update</button>
                  <button class="btn btn-sm btn-secondary" @click="goBack">Cancel</button>
                </footer>
              </div>
            </div>
            <div v-if="activeTab === 'Quotations'">
              <div class="DesignProjectTimeLineWrapper">
                <div class="d-flex justify-content-end">
                  <button
                    @click="openAddQuotationModal('null')"
                    class="btn btn-sm btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#AddQuotation"
                  >
                    + Add Quotation
                  </button>
                </div>

                <div class="DesignProjectLinePage">
                  <section class="table-responsive">
                    <table class="DesignProjectTimeLine files-table">
                      <caption class="d-none">
                        Quotation List
                      </caption>
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Date</th>
                          <th>Project name</th>
                          <th>Quotation Value</th>
                          <th>Status</th>
                          <th>Remark</th>
                          <th class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in Quotations" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td class="text-nowrap text-left">
                            {{ formatDate(row.date) }}
                          </td>
                          <td>{{ row["onSiteProjects.projects.projectName"] }}</td>
                          <td>
                            {{ row.quotationValue }}
                          </td>
                          <td>
                            {{ row.status }}
                          </td>

                          <td>
                            {{ row.remark }}
                          </td>

                          <td class="text-center">
                            <div class="ActionImage">
                              <span
                                @click="
                                  $viewFile(
                                    row['awsFiles.awsFileName'],
                                    row['awsFiles.fileName'],
                                    'onSiteQuotation',
                                    'onSite'
                                  )
                                "
                              >
                                <img
                                  src="../../../assets/icons/view.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Edit"
                                  alt=""
                                />
                              </span>
                              <span>
                                <img
                                  src="../../../assets/icons/pinkDownold.png"
                                  alt=""
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="downold"
                                  @click="
                                    $downloadFile(
                                      row['awsFiles.awsFileName'],
                                      row['awsFiles.fileName'],
                                      'onSiteQuotation',
                                      'onSite'
                                    )
                                  "
                                />
                              </span>

                              <span
                                @click="openAddQuotationModal(row.id)"
                                data-bs-toggle="modal"
                                data-bs-target="#AddQuotation"
                              >
                                <img
                                  src="../../../assets/icons/yellowEdit.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Edit"
                                  alt=""
                                />
                              </span>
                              <span @click="DeleteQuotation(row.id, index)">
                                <img
                                  src="../../../assets/icons/redDelete.png"
                                  data-toggle="tooltip"
                                  data-placement="top-start"
                                  title="Delete"
                                  alt=""
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AddQuotationModal
    :contractorId="ContractorId"
    :quotationId="QuotationId"
    @fetchQuotations="featchQuotations"
  />
</template>

<script>
import AddQuotationModal from "../../Modal/AddQuotationModal";
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import axios from "axios";

export default {
  name: "OnSiteProjectProfile",
  components: {
    Header,
    Nav,
    AddQuotationModal,
  },
  data() {
    return {
      ContractorId: "",
      activeTab: "contractorDetails",
      form: {
        name: "",
        contactNo: "",
        email: "",
        contractorNumber: "",
        address: "",
        city: "",
        state: "",
        pinCode: "",
        secondaryContactName: "",
        secondaryContactEmail: "",
        secondaryContactNumber: "",
        id: null,
        newCategory: null,
        removedCategory: null,
      },
      QuotationId: null,
      Quotations: [],
      categories: [],
      bankDetails: {
        beneficiaryName: "",
        accountName: "",
        accountNumber: "",
        bankName: " ",
        IFSC: "",
        branchAddress: " ",
        entityId: null,
        entityName: "Contractor",
        id: null,
      },
    };
  },
  mounted() {
    this.ContractorId = this.$route.params.contractorId;
    this.fetchCategories();
    this.featchQuotations();

    this.featchContractorById();

    this.featchBankDetails();
  },
  methods: {
    openAddQuotationModal(QuotationIds) {
      this.QuotationId = QuotationIds;
      console.log(this.QuotationId);
    },
    formatDate(dateStr) {
      if (!dateStr) return "";
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    async featchQuotations() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/OnSiteQuotation/${this.ContractorId}`
        );
        this.Quotations = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async DeleteQuotation(QuotationIds, index) {
      this.Quotations.splice(index, 1);
      console.log(index);
      try {
        await axios.delete(`${this.$apiEndPoint}/Quotations/${QuotationIds}`);
        this.featchQuotations();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async featchBankDetails() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/bankDetails/byEntityId/${this.ContractorId}/Contractor`
        );
        this.bankDetails = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async featchContractorById() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/onSiteContractor/byId/${this.ContractorId} `
        );

        this.form.name = response.data.name;
        this.form.contactNo = response.data.contactNo;
        this.form.email = response.data.email;
        this.form.contractorNumber = response.data.contractorNumber;
        this.form.contractorCategory = response.data.Categories[0]["onSiteCategory.id"];
        this.form.address = response.data.address;
        this.form.city = response.data.city;
        this.form.state = response.data.state;
        this.form.pinCode = response.data.pinCode;
        this.form.secondaryContactEmail = response.data.secondaryContactEmail;
        this.form.secondaryContactName = response.data.secondaryContactName;
        this.form.secondaryContactNumber = response.data.secondaryContactNumber;
        this.form.id = response.data.id;
        this.form.removedCategory = response.data.Categories[0]["onSiteCategory.id"];
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/OnSiteCategory`);
        this.categories = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async saveForm() {
      console.log(this.form);
      const contractorDetails = {
        ...this.form,
        removedCategory: [this.form.removedCategory],
        newCategory: [this.form.contractorCategory],
      };
      console.log(contractorDetails);
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/onSiteContractor/${this.ContractorId}`,
          contractorDetails
        );
        console.log("Form Updated saved:", response.data);
        this.$toast(`Vendor Data Updated:.`, "success");
        const contractorID = this.form.id; // Ensure the ID is obtained from response data
        if (this.bankDetails.id !== null) {
          await this.UpdateBankDetails(contractorID);
        } else {
          await this.createBankDetails(contractorID);
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async createBankDetails(contractorID) {
      try {
        this.bankDetails.entityId = contractorID;
        const response = await axios.post(
          `${this.$apiEndPoint}/bankDetails`,
          this.bankDetails
        );
        console.log("Bank details created:", response.data);
        // this.$toast(` Bank details created:.`, "success");
        this.featchBankDetails();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async UpdateBankDetails() {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/bankDetails/${this.ContractorId}/Contractor`,
          this.bankDetails
        );
        console.log("Bank details Updated:", response.data);
        // this.$toast(` Bank details Updated.`, "success");
      } catch (error) {
        this.$handleError(error);
      }
    },
    goBack() {
      this.$router.go(-1); // Navigate back one step in the history stack
    },
  },
};
</script>

<style scoped>
.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  margin: 0 5px;
  cursor: pointer;
}

.ActionImage img:hover {
  background-color: #ccc;
  border-radius: 50%;
}

.OnSiteProjectHeader {
  display: flex;
  flex-direction: column;
}

.OnSiteProjectComponentHeader {
  display: flex;
  flex-direction: column;
}

.OnSiteProjectHeaderRow1 {
  display: flex;
  align-items: center;
}

.mainHeading {
  flex: content;
}

#OnSiteProjectHeaderImage {
  width: 45px;
  height: 45px;
}

.OnsiteProjectHeaderRow2 {
  display: flex !important;
  justify-content: space-around;
}

.DesignProjectComponentHeader {
  display: flex;
  align-items: center;
  padding: 0.5% 0;
  position: sticky;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  top: 0;
  background-color: #ffffff;
}

.DesignProjectComponent .DesignProjectComponentHeader::-webkit-scrollbar {
  display: none;
}

#DesignProjectHeaderImage {
  width: 50px;
  height: 50px;
  margin-left: 0.5%;
  display: flex;
}

.DesignProjectHeader {
  display: flex;
  flex-direction: column;
  position: relative;
}

.DesignProjectHeader .DesignProjectHeaderRow1 {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 3%;
  width: 50%;
}

.DesignProjectHeader .DesignProjectHeaderRow1 input {
  display: inline-block;
  max-width: fit-content;
  padding: 1%;
  border: none;
  outline: none;
  color: black;
  font-size: 18px;
  flex-grow: 1;
  /* Allows the input to grow to fit the content */
  margin-right: 1%;
  /* Adds space between the input and the next icon */
}

.DesignProjectHeader .DesignProjectHeaderRow1 input:hover {
  outline: 1px solid #ccc;
  border-radius: 5px;
}

.DesignProjectHeader .DesignProjectHeaderRow1 img {
  display: inline-block;
  margin: 0 2%;
  width: 20px;
  cursor: pointer;
}

.DesignProjectHeader .DesignProjectHeaderRow2 {
  display: flex;
  align-items: center;
  width: 100%;
}

.DesignProjectHeader .DesignProjectHeaderRow2 h6 {
  margin: 0 3%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
}

.inactive-SubHeading {
  font-size: 14px;

  color: #868686;
  font-weight: 500;
}

.ProjectName:hover {
  outline: 1px solid #ccc;
}

.active-SubHeading {
  font-size: 14px;
  text-decoration: underline;
  color: #000000;
  font-weight: 500;
}

.DesignProjectHeaderUsers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.DesignProjectHeaderUsers button {
  padding: 2px 4px;
  margin: 0 30px;
}

.DesignProjectHeaderUsers img {
  width: 25px;
  height: 25px;
  align-items: center;
}

#BellImage {
  margin-right: 40%;
}

.MOBILE {
  display: none;
}

.WEB {
  display: block;
}

.redSubHeading {
  color: #ff6b00;
  font-size: 16px;
  margin-left: 3px;
}

.contractorDetails {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.contractorDetailsHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  border-bottom: 2px solid #000000;
}

.contractorPrimaryDetails {
  margin-bottom: 30px;
}

.headerTitle {
  flex: 1;
  text-align: left;
  padding: 10px;
}

.headerIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
  position: absolute;
}

label,
input {
  font-size: 14px;
}

.footer {
  position: fixed;
  bottom: 12px;
  border-radius: 0 0 15px 15px;

  width: calc(100% - 74px);
  background: white;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  /* Box shadow at the top */
  padding: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

.DesignProjectTimeLineWrapper button {
  border-radius: 5px;
  padding: 0.5% 1%;
  margin: 1% 2% 0% 2%;

  display: block;
}
.DesignProjectTimeLine {
  width: calc(96% - 74px);
  margin: 0 2%;
  border-collapse: collapse;
  overflow-x: scroll;
}

.files-table {
  overflow: scroll;
  margin: 0 2%;
  width: calc(96%);
  z-index: 999;
  margin-top: 10px;
  border-collapse: collapse;
}

.files-table th,
.files-table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #ddd;
}

.files-table thead tr {
  border-bottom: 2px solid #ccc;
}

.files-table th {
  background-color: #f8f8f8;
}

.files-table th:first-child {
  border-radius: 5px 0px 0px 5px;
}
.files-table th:last-child {
  border-radius: 0px 5px 5px 0px;
}

@media screen and (max-width: 768px) {
  .files-table {
    white-space: nowrap;
  }
  .DesignProjectComponentHeader {
    overflow-x: scroll;
  }
  .WEB {
    display: none !important;
  }

  .MOBILE {
    display: block !important;
  }
  .headerTitle {
    margin-bottom: 10px;
  }

  .footer {
    position: fixed;
    bottom: 0px;
    border-radius: 0 0 15px 15px;
    width: calc(100%);
    background: white;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
    /* Box shadow at the top */
    padding: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }

  .contractorPrimaryDetails {
    margin-bottom: 120px;
  }

  .headerIcon {
    align-self: flex-end;
  }
  .DesignProjectTimeLine {
    overflow-x: scroll;
  }
}
</style>
