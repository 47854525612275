<template>
  <div style="margin: 10px">
    <apexchart
      ref="chart"
      height="550"
      width="1250"
      class="ms-2"
      type="bar"
      :options="chartOptions"
      :series="series"
    >
    </apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "ChartComponent",
  props: {
    chartData: Object, // Data object {stageName: count}
  },
  components: {
    apexchart: VueApexCharts,
    // Bar
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: "vuechart-example",
          // Add padding
          toolbar: {
            autoSelected: "pan",
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "#000000",
              fontWeight: "600", // Change color to black
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#000000",
              fontWeight: "600", // Change color to black
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            borderRadiusApplication: "end",
            horizontal: true,
            // Decrease bar height
            barHeight: "50%", // You can adjust this value as needed
          },
        },
      },
      series: [],
    };
  },

  mounted() {
    // Render the chart when the component is mounted
    this.updateChart();
  },
  watch: {
    // Watch for changes in chartData prop and update the chart accordingly
    chartData: {
      handler(newValue) {
        this.updateChart(newValue);
      },
      immediate: true, // Trigger on initial mount
    },
  },
  methods: {
    // Method to update the chart
    updateChart() {
      this.series = [
        {
          name: "Number Of Leads",
          data: Object.values(this.chartData),
        },
      ];
      this.chartOptions.xaxis.categories = Object.keys(this.chartData);
    },
  },
};
</script>
<style scoped>
/* Add any custom styles for the chart component */
.chart-container {
  position: relative;
  height: 580px;
  padding: 20px;
}

.lead-count {
  position: absolute;
  top: 10px;
  right: 200px;
  font-size: 16px;
  font-weight: 800;
}
.chart-canvas {
  border-color: brown;
  width: 100% !important;
}
</style>
